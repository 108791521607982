export const PermissionEnum = {
  //#region  Inventory Module
  Inventory_Dashboard: 0x2275,
  Inventory_Setup: 0x2276,
  Inventory_InventoryStore: 0x2277,
  Inventory_CentralRequest: 0x2278,
  Inventory_RegionalRequest: 0x2279,
  Inventory_UserRequest: 0x2280,
  Inventory_BatchApproval: 0x2281,
  Inventory_BatchConfirmation: 0x2282,
  Inventory_StockPolicy: 0x2283,
  Inventory_RequestAudit: 0x2284,
  Inventory_StockPosition: 0x2285,
  Inventory_StockReceipt: 0x2286,
  Inventory_StockIssue: 0x2287,
  Inventory_BatchRequestTracking: 0x3404,
  Inventory_AggregatedView: 0x3455,
  //#endregion

  //#region  Convergence Module - Incidence Management
  Convergence_Incident_Setup: 0x2288,
  Convergence_Incident_Setting: 0x2289,
  Convergence_Incident_CaptureIncidence: 0x2290,
  Convergence_Incident_ReviewIncidence: 0x2291,
  Convergence_Incident_ApproveIncidence: 0x2292,
  Convergence_Incident_NotificationTemplate: 0x2293,
  Convergence_Incident_NotificationPolicy: 0x2294,
  Convergence_Incident_ClientNotification: 0x2295,
  Convergence_Incident_Dashboard: 0x2296,
  Convergence_Incident_Report: 0x3000,
  Convergence_Incident_CaptureMobile: 0x3001,
  Convergence_Incident_ClientMappingByState: 0x3002,
  //#endregion

  //#region  Convergence Module - Device Management
  Convergence_Device_Setup: 0x2297,
  Convergence_Device_DeviceRegistration: 0x2298,
  Convergence_Device_MapClientDevice: 0x2299,
  Convergence_Device_DashboardClientDistribution: 0x2300,
  Convergence_Device_DashboardControlView: 0x2301,
  //#endregion

  //#region  Organization Module
  Organization_Organogram: 0x2302,
  Organization_LineManagerApproval: 0x2303,
  //#endregion

  //#region Secured Mobility
  Secured_Mobility_Dashboard: 0x2304,
  Secured_Mobility_SMORouteAndRegion: 0x2305,
  Secured_Mobility_ResourceType: 0x2306,
  Secured_Mobility_ResourceRegistration: 0x2307,
  Secured_Mobility_ResourceLookUp: 0x2308,
  Secured_Mobility_RouteStateMap: 0x2309,
  Secured_Mobility_VehicleDriverMap: 0x2310,
  Secured_Mobility_ServiceRegistration: 0x2311,
  Secured_Mobility_BusinessRule: 0x2312,
  Secured_Mobility_PriceRegister: 0x2313,
  Secured_Mobility_PreferentialPricing: 0x2314,
  Secured_Mobility_ResourceTie: 0x2315,
  Secured_Mobility_ResourceScheduling: 0x2316,
  Secured_Mobility_ServiceAssignment: 0x2317,
  Secured_Mobility_JourneystartStop: 0x2318,
  Secured_Mobility_ResourceRank: 0x2319,
  Secured_Mobility_AdminEndJourney: 0x2320,
  Secured_Mobility_StatusDashboard: 0x2321,
  //#endregion

  //#region Guard Management Module
  GMA_Dashboard: 0x3010,
  GMA_Settings: 0x3011,
  GMA_ItemSetup: 0x3012,
  GMA_Setup: 0x3013,
  GMA_CreateJob: 0x3014,
  GMA_ManageJob: 0x3015,
  GMA_Redeployment: 0x3016,
  GMA_OperativeManagement: 0x3017,
  GMA_TrainingSchool: 0x3018,
  GMA_Reaccreditation: 0x3019,
  GMA_MaterialRequest: 0x3020,
  GMA_MaterialRefresh: 0x3021,
  GMA_MaterialReplacement: 0x3022,
  GMA_MaterialDistribution: 0x3023,
  GMA_SupervisionPolicy: 0x3024,
  GMA_ClusterReporting: 0x3025,
  GMA_StationAbsenceRequest: 0x3026,
  GMA_StationAbsenceApproval: 0x3027,
  GMA_ZonalMapView: 0x3028,
  GMA_OrgStructure: 0x3029,
  GMA_Location: 0x3030,
  GMA_BeatAllocation: 0x3031,
  GMA_Guarantor: 0x3032,
  GMA_Facilitator: 0x3033,
  GMA_TrainingFacility: 0x3034,
  GMA_ApplicantListing: 0x3035,
  GMA_Approval: 0x3036,
  GMA_TrainingSchedule: 0x3037,
  GMA_Sanction: 0x3038,
  GMA_BioSearch: 0x3039,
  GMA_GuardRegistration: 0x3040,
  //#endregion

  //#region Payroll Management Module
  Payroll_GenericSettings: 0x3200,
  Payroll_SpecialSettings: 0x3201,
  Payroll_TaxSettings: 0x3202,
  Payroll_PayGrade: 0x3203,
  Payroll_PayGroup: 0x3204,
  Payroll_OperativeAttachment: 0x3205,
  Payroll_PayrollRuns: 0x3206,
  Payroll_PayrollManagement: 0x3207,
  Payroll_PayrollVsContract: 0x3208,
  Payroll_PayeByState: 0x3209,
  Payroll_QtyPaidVsQtyBilled: 0x3210,
  Payroll_ApprovalSetup: 0x3211,
  Payroll_BatchApproval: 0x3212,
  Payroll_SingleApproval: 0x3213,
  Payroll_SpecialAllowanceRequest: 0x3214,
  Payroll_SpecialDeductionRequest: 0x3215,
  Payroll_SpecialRequestApproval: 0x3216,
  //#endregion

  //#region Complaints Management Module
  Complaints_Registration: 0x3400,
  Complaints_Handling: 0x3401,
  Complaints_Tracking: 0x3402,
  Complaints_EscalationManagement: 0x3403,
  //#endregion

  //#region Platform Benefit Management Module
  PlatformBenefit_CreatePlatform: 0x3450,
  PlatformBenefit_CreateBenefit: 0x3451,
  PlatformBenefit_MapPlatformService: 0x3452,
  PlatformBenefit_MapServiceBenefit: 0x3453,
  PlatformBenefit_SpecialRequestWorkbench: 0x3454,
  //#endregion

  ManagementDashboard: 0x3500,
  Management_ProductionDashboard: 0x3588,
  Management_ApprovalDashboard: 0x3589,

  //#region Finance Management Module
  Finance_Reversal_ReverseReceipts: 0x3501,
  Finance_Reversal_ReverseInvoice: 0x3502,
  Finance_Reversal_ReverseVoucher: 0x3503,
  Finance_ReversalOffice: 0x3504,
  Finance_ExpenseTool_ExpenseLineSetup: 0x3505,
  Finance_ExpenseTool_RequesterAttachment: 0x3506,
  Finance_ExpenseTool_ApprovalSetup: 0x3507,
  Finance_ExpenseTool_ExpenseBudget: 0x3508,
  Finance_ExpenseTool_UserRequest: 0x3509,
  Finance_ExpenseTool_LineApproval: 0x3510,
  Finance_AdvancePayment_Reversal: 0x3511,
  Finance_VoucherPosting: 0x3512,
  Finance_ExpenseTool_Approval: 0x3513,
  Finance_ExpenseTool_AuthorizationSetup: 0x3514,
  Finance_ExpenseTool_Authorization: 0x3515,
  Finance_ExpenseTool_Payables: 0x3516,
  Finance_ExpenseTool_Payment: 0x3517,
  Finance_ExpenseTool_BankPayment: 0x3518,
  Finance_ExpenseTool_RequestTracker: 0x3519,
  Finance_PrintCheque: 0x3520,
  Finance_BankAccount: 0x3521,
  Finance_InvoiceUpdate: 0x3522,
  Finance_BulkSettlement: 0x3523,
  Account_Reconciliation: 0x3524,
  Finance_CreditNoteReversal: 0x3525,
  Finance_InvoiceReplacement: 0x3526,
  Finance_RawInflowUpload: 0x3527,
  Finance_RawInflowConfirmation: 0x3528,
  Finance_AutoRenewPolicy: 0x3529,
  Finance_DtrackTransmission: 0x3530,
  //#endregion

  //#region Supplier Module
  Supplier_BankAccounts: 0x3550,
  //#endregion

  //#region Approval Module
  System_SingleApproval: 0x3570,
  System_BulkApproval: 0x3571,
  System_ApprovalChecklist: 0x3572,
  System_ApprovalSequence: 0x3573,
  //#endregion

  //#region Client Management Module
  Response_Team_Setup: 0x3600,
  Client_CE_Request: 0x3581,
  Client_CE_Approval: 0x3582,
  Client_ClientMerge_ApprovalSetup: 0x3583,
  Client_ClientMerge_Request: 0x3584,
  Client_ClientMerge_Approval: 0x3585,
  Client_AmortizationView: 0x3586,
  Client_RenewalView: 0x3587,
  Client_ExpressProfomaInvoice: 0x3590,
  Client_ExpressProfomaCloseOut: 0x3591,
  Client_Contract_Renew_Notification: 0x3592,
  Client_Service_Cost_Analysis: 0x3593,
  Client_InformationUpdate: 0x3594,
  Client_InformationUpdate_ContractService: 0x3595,
  Client_BranchAttachment: 0x3596,
  Client_BranchMemberAttachment: 0x3597,
  Client_SBU_User_Attachment: 0x3598,
  Client_SBU_Client_Attachment: 0x3599,
  //#endregion

  //#region Response Module
  Client_CE_ApprovalPage: 0x3580,
  Response_TeamType_Setup: 0x3601,
  Resting_Station_Setup: 0x3602,
  Resting_Station_LGA_Map_Setup: 0x3603,
  Response_Team_Resting_Station_Map: 0x3604,
  //#endregion

  //#region Budget Management Module
  Budget_SeasonSettings: 0x3700,
  Budget_CycleSettings: 0x3701,
  Budget_Distributions: 0x3702,
  Budget_Approval_Setup: 0x3703,
  Budget_Approval_Page: 0x3704,
  Budget_Reviewer_Setup: 0x3705,
  Budget_Reviewer_Page: 0x3706,

  //#endregion
};
Object.freeze(PermissionEnum);

export const PermissionGroupEnum = {
  Inventory: 1,
  Payroll: 2,
  Premployment: 3,
  Convergence_Incident: 4,
  Convergence_Device: 5,
  Organization: 6,
  SecuredMobility: 7,
  GMA: 8,
  GMA_Settings: 9,
  GMA_JobAndOperatives: 10,
  GMA_Materials: 11,
  GMA_Supervision: 12,
  GMA_OgranizationStructure: 13,
  GMA_Listings: 14,
  Payroll_Settings: 15,
  Payroll_PayrollViews: 16,
  Complaint: 17,
  PlatformBenefit: 18,
  Management: 19,
  Finance: 20,
  Finance_Reversals: 21,
  Finance_ExpenseTool: 21,
  Supplier: 22,
  System: 23,
  Client: 24,
  Response: 25,
  ClientMerge: 26,
  Budget: 27,
  Budget_Reviewal: 28,
  Budget_Approval: 29,
};

export interface Permission {
  PermissionGroup: number;
  PermissionCode: number;
  PermissionAction: string;
}
