import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  Branch,
  CData,
  CommonResponse,
  Constant,
  Contract,
  ContractService,
  CustomerDivision,
  EndorsementType,
  GeneralService,
  GenericKeyValueType,
  GroupContractCategory,
  GroupQuoteCategory,
  Lead,
  LeadDivision,
  LeadQuote,
  Office,
  PostDateEndrosementForApprovalVM,
  QuoteServiceDocument,
  QuoteServices,
  SBUQuoteServiceProportion,
  SbuAttachment,
  ServiceCategory,
  ServiceCategoryTask,
  ServicePricing,
  ServiceRelationshipEnum,
  Services,
  User,
} from "../../../interfaces/home";
import {
  ConfirmationService,
  MessageService,
  TreeDragDropService,
  SelectItem,
  TreeNode,
  Message,
} from "primeng/api";
import { RoleService } from "../../../services/role.service";
import { UserService } from "../../../services/user.service";
import { environment } from "../../../../environments/environment";
import { CustomerDivisionService } from "../../../services/customer-division.service";
import { OperatingEntityService } from "../../../services/operating-entity.service";
import { ServiceCategoryService } from "../../../services/service-category.service";
import { DivisionService } from "../../../services/division.service";
import { ServicesService } from "../../../services/services.service";
import { LeadService } from "../../../services/lead.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService, DynamicDialogRef } from "primeng/dynamicdialog";
import { QuoteService } from "../../../services/quote.service";
import { ServiceQualifyComponent } from "../../lead-admin/lead-qualification/service-qualify/service-qualify.component";
import { EndorsementTypeService } from "../../../services/endorsement-type.service";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import { GroupInvoiceFormComponent } from "../../lead-admin/group-invoice-form/group-invoice-form.component";
import { EndorsementService } from "../../../services/endorsement.service";
import { ServiceCategoryTaskService } from "src/app/services/service-category-task.service";
import { ServicePricingService } from "src/app/services/service-pricing.service";
import { forkJoin, Observable } from "rxjs";
import { AngularFireStorage } from "@angular/fire/storage";
import { timeStamp } from "console";
import { FileUpload } from "primeng/fileupload";
import { AdminPriceComponent } from "./admin-price/admin-price.component";
import { Quote, templateJitUrl } from "@angular/compiler";
import { ViewQuoteComponent } from "../../lead-admin/lead-qualification/view-quote-component/view-quote.component";
import { BranchService } from "src/app/services/branch.service";
import { ContractServiceService } from "src/app/services/contract-service.service";
import { GenerateGroupInvoiceService } from "src/app/services/generate-group-invoice.service";
import { TabPanel, TabView } from "primeng/tabview";
import { ServiceClosureComponent } from "../../lead-admin/conversion/service-closure/service-closure.component";
import { SbuInfoComponent } from "./sbu-info/sbu-info.component";
import { finalize } from "rxjs/operators";
import { FileStorageService } from "src/app/services/file-storage.service";
import { ContractsService } from "src/app/services/contracts.service";
import { FireBaseAuthService } from "src/app/services/fire-base-auth.service";
import { StrategicBusinessUnitService } from "src/app/services/strategic-business-unit.service";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-client-endorsement",
  templateUrl: "./client-endorsement.component.html",
  styleUrls: ["./client-endorsement.component.css"],
  providers: [
    TreeDragDropService,
    MessageService,
    ConfirmationService,
    DialogService,
    Constant,
  ],
})
export class ClientEndorsementComponent implements OnInit {
  additionContractChoice: number | null;
  public qualifyServiceRef: DynamicDialogRef;

  @ViewChild("formWrapper") public formWrapper: ElementRef;
  // @ViewChild(TabPanel) sbuInfoTabPanel: TabPanel;
  activeTabIndex: number = 0;
  currentLead: Lead = null;
  serviceTree: TreeNode[] = [];
  serviceTreeChildren: TreeNode[] = [];
  loadingServices = true;
  loadingService = true;
  loadingServicesCategory = false;
  availableServices: Services[];
  selectedServices: Services[];
  selectedQuoteService: QuoteServices[];
  selectedGeneralService: any[] = [];
  selectedCategories: ServiceCategory;
  draggedService: Services;
  selectedServiceTotal: number;
  qualifyRef: DynamicDialogRef;
  services: Services[] = [];
  invoiceGroupRef: DynamicDialogRef;
  adminPriceRef: DynamicDialogRef;
  isInvoiceGrouped: boolean;
  invoiceGroupData: any;

  clientForm: FormGroup;
  edForm: FormGroup;
  customerDivisions: any;
  selectedCustomerDivision: any;
  viewedContractService: null;
  customerDivisionInformation: any;
  selectedContract: Contract;
  contracts: any[];
  primaryContactFullName: string;
  secondaryContactFullName: string;
  fetchingContractServices = false;
  contractServices: any[];
  selectedContractService: any;
  contractServiceCols: any;
  endorsementTypes: EndorsementType[];
  selectedEndorsementType: any;
  endorseContractClicked = false;
  endorseContractServiceClicked = false;
  valueChangeForm: FormGroup;
  renewalForm: FormGroup;
  contractServiceUnderEndorsement: any;
  unitPrice: number;
  quantity: number;
  possibleStartDates: any[];
  selectedStartDate: any;
  groupInvoiceNumber: string;
  qualifiableContractServices: any[];
  contractIsDueForRenewal: boolean;
  dateEligibleForRenewal: any;

  originalUniqueTag: string;
  uniqueTagError: string;

  fetchingServiceCategoryTask: boolean;
  serviceCategoryTasks: ServiceCategoryTask[];
  contractServiceIsDueForRenewal: boolean;

  serviceCancelForm: FormGroup;

  uploadedDocuments: any[] = [];
  documentUrls: string[] = [];
  fileUploader: FileUpload;

  sampleForm: FormGroup;

  canModifyQuantity: boolean = true;
  quantityTracker: number = 0;
  disableCancellation: boolean = false;
  shouldPairAdmin_CreditNote: boolean = false;
  adminPair: any;
  minAdminPrice: number;
  maxAdminPrice: number;
  changedAdminPrice: number;

  valueChangeIsSubmitted: boolean = false;
  renewalIsSubmitted: boolean = false;
  cancelFormIsSubmitted: boolean = false;

  priceRangeErrorValueChange: string;
  priceRangeErrorRenewal: string;
  directAffectAdminNotice: string;

  minStartDate: Date;
  maxStartDate: Date;
  maxEndDate: Date;
  minEndDate: Date;

  paymentCycles: CData[];
  invoicingIntervals: CData[];

  billableAmountTotal: number;
  leadEngagementRef: DynamicDialogRef;
  viewQuoteRef: DynamicDialogRef;
  tempIdAssignment: number = 0;
  invoiceTypeSelector: FormGroup;
  //selectedCategory: number = 0;
  branches: Branch[] = [];

  selectedDivisionIdTracking: number = 0;
  additionIsSubmitted: boolean = false;
  offices: any[] = [];

  selectedCategory: any = null;
  categories: any[] = [
    { name: "Select a category for this contract service", key: "0" },
    // { name: "Individual contract with separate qualifications", key: "1" },
    { name: "Group contract with same contract qualifications", key: "2" },
    // { name: "Group contract with different contract qualifications", key: "3" },
  ];
  canChangeInvoiceCategory: boolean = true;
  public newContract: any = {};

  isGroupUpload: boolean = false;
  quoteId: number = 0;
  canUploadGroupDocs: boolean = true;
  validatingAddition: boolean = false;
  fetchingSBUInfo: boolean = true;
  filteredContractServices: ContractService[];
  includeExpiredContractService: boolean = false;
  trackingAdminPrice: number;
  hasPendingSBU: boolean = false;
  contractIsSelected: boolean;
  openBulkRenewal: boolean;
  openBulkCancellation: boolean;
  allPaymentCycles: CData[];
  thePaymentCycle: CData;
  allInvoiceCycles: CData[];
  theInvoiceCycle: CData;
  renewalStartDate: Date;
  renewalEndDate: Date;
  renewalUniqueTag: string;
  renewalDescription: string;
  uploadedDocs: any[] = [];
  allContractServicesRenewable: ContractService[];
  selectedContractServicesRenewable: ContractService[] = [];
  renewalCols: any[];
  affectedCols: any[];
  allCancellatonDateOptions: {
    startDate: Date;
    validDate: boolean;
  }[];
  theCancellationDateOption: {
    startDate: Date;
    validDate: boolean;
  };
  cancellationDescription: string;
  uploadedCancellationDocs: any[] = [];
  allContractServicesCancellable: ContractService[];
  selectedContractServicesCancellable: ContractService[] = [];
  cancelCols: any[];
  contractName: string;
  isrequired: boolean = false;
  allServiceCategories: ServiceCategory[] = [];
  preGroupedContractServices: ContractService[] = [];
  hasSuccessfulValidation: boolean;
  msg: Message[] = [];
  customerDivisionInView: CustomerDivision;
  allSbuAttachments: SbuAttachment[] = [];
  applyToOnlyCurrentMonth: number = null;
  allUsers: User[] = [];
  openDateEndorse: boolean;
  isDifferentMonthChange: boolean;
  newActivationDate: Date;
  allContractServicesAffected: ContractService[] = [];
  selectedContractServicesAffected: ContractService[] = [];
  activationDayOptions: GenericKeyValueType<number, string>[] = [];
  theNewActivationDay: GenericKeyValueType<number, string>;
  activationMonthOptions: GenericKeyValueType<number, string>[] = [];
  theActivationMonth: GenericKeyValueType<number, string>;
  newActivationYear: number;

  constructor(
    public constant: Constant,
    public formBuilder: FormBuilder,
    private roleService: RoleService,
    private userService: UserService,
    private strategicBusinessUnitService: StrategicBusinessUnitService,
    private operatingEntityService: OperatingEntityService,
    private serviceCategoryService: ServiceCategoryService,
    private contractService: ContractsService,
    public divisionService: DivisionService,
    private servicesService: ServicesService,
    public leadService: LeadService,
    public router: Router,
    private route: ActivatedRoute,
    public dialogService: DialogService,
    private quoteService: QuoteService,
    private customerDivisionService: CustomerDivisionService,
    private endorsementTypeService: EndorsementTypeService,
    private endorsementService: EndorsementService,
    private serviceCategoryTaskService: ServiceCategoryTaskService,
    private servicePricingService: ServicePricingService,
    private angularFireStorage: AngularFireStorage,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService,
    private branchService: BranchService,
    private contractServiceService: ContractServiceService,
    private generateGroupInvoiceService: GenerateGroupInvoiceService,
    private fileStorageService: FileStorageService,
    private fireStorageService: FireBaseAuthService,
    private utilService: UtilService
  ) {
    this.selectedServiceTotal = 0;
    this.availableServices = [];
    this.selectedServices = [];
    this.selectedQuoteService = [];
    this.selectedGeneralService = [];
  }

  async ngOnInit() {
    this.selectedCategory = this.categories[0];

    this.paymentCycles = this.constant.paymentCycles;
    this.invoicingIntervals = this.constant.invoicingIntervals;
    this.allPaymentCycles = this.constant.paymentCycles;
    this.allInvoiceCycles = this.constant.invoicingIntervals;

    this.breadcrumbService.setItems([
      { label: "Client-Endorsement", routerLink: ["home/client-endorsement"] },
    ]);

    this.GetAllServiceCategories();
    this.fetchServiceCategoryTask();

    this.setUpForms();

    await this.FetchAllUsers();
    await this.loadServices();
    await this.loadDivisions();

    this.fetchBranches();

    this.serviceTree = [
      {
        label: "Halogen",
        data: "Storage Folder",
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: this.serviceTreeChildren,
      },
    ];

    this.renewalCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.cancelCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.affectedCols = [
      { field: "caption", header: "Caption" },
      { field: "description", header: "Description" },
    ];

    this.contractIsSelected = false;
    this.FetchAllSBUAttachments();
    this.getCustomerDivisions();
    this.getEndorsementTypes();
    this.ResetMessageToasters();
    this.PopulateAllDays();
    this.PopulateAllMonths();
  }

  ResetMessageToasters() {
    this.msg = [];
    this.msg.push({
      severity: "info",
      summary:
        "Please always make use of the contract validity button before performing endorsement.",
    });
  }

  PopulateAllDays() {
    this.activationDayOptions = [
      { Key: 1, Value: "Day 1" },
      { Key: 2, Value: "Day 2" },
      { Key: 3, Value: "Day 3" },
      { Key: 4, Value: "Day 4" },
      { Key: 5, Value: "Day 5" },
      { Key: 6, Value: "Day 6" },
      { Key: 7, Value: "Day 7" },
      { Key: 8, Value: "Day 8" },
      { Key: 9, Value: "Day 9" },
      { Key: 10, Value: "Day 10" },
      { Key: 11, Value: "Day 11" },
      { Key: 12, Value: "Day 12" },
      { Key: 13, Value: "Day 13" },
      { Key: 14, Value: "Day 14" },
      { Key: 15, Value: "Day 15" },
      { Key: 16, Value: "Day 16" },
      { Key: 17, Value: "Day 17" },
      { Key: 18, Value: "Day 18" },
      { Key: 19, Value: "Day 19" },
      { Key: 20, Value: "Day 20" },
      { Key: 21, Value: "Day 21" },
      { Key: 22, Value: "Day 22" },
      { Key: 23, Value: "Day 23" },
      { Key: 24, Value: "Day 24" },
      { Key: 25, Value: "Day 25" },
      { Key: 26, Value: "Day 26" },
      { Key: 27, Value: "Day 27" },
      { Key: 28, Value: "Day 28" },
      { Key: 29, Value: "Day 29" },
      { Key: 30, Value: "Day 30" },
      { Key: 31, Value: "Day 31" },
    ];
  }

  PopulateAllMonths() {
    this.activationMonthOptions = [];
    for (let i = 1; i <= 12; i++)
      this.activationMonthOptions.push({
        Key: i,
        Value: this.utilService.GetMonthLabel(i),
      });
  }

  async FetchAllSBUAttachments() {
    this.strategicBusinessUnitService.GetAllSBUAttachments().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: data.responseMsg,
          });
          return;
        }

        this.allSbuAttachments = data.responseData;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all sbu attachments at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(
      async (r) => {
        var data = r.responseData ?? [];
        this.allUsers = data;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all users at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  fetchServiceCategoryTask() {
    this.serviceCategoryTaskService.allServiceCategoryTask().subscribe(
      async (serviceCategoryTask: CommonResponse) => {
        this.serviceCategoryTasks = serviceCategoryTask.responseData;
        this.fetchingServiceCategoryTask = false;
      },
      (error) => {
        this.fetchingServiceCategoryTask = false;
        this.connectionError();
      }
    );
  }

  toggleExpiredContractService() {
    if (this.includeExpiredContractService)
      this.filteredContractServices = this.contractServices;
    else
      this.filteredContractServices = this.contractServices.filter(
        (x) => x.isActive
      );
  }

  viewOtherContactInfo() {}

  private generateInvoiceNumber() {
    this.generateGroupInvoiceService
      .generateInvoice()
      .subscribe((result: CommonResponse) => {
        this.newContract.groupInvoiceNumber = result.responseData;
      });
  }

  changeInvoiceCategory() {
    let index = this.selectedCategory?.key;
    this.isInvoiceGrouped = false;

    if (index == GroupQuoteCategory.GroupQuoteWithSameDetails) {
      this.isInvoiceGrouped = true;
      this.canChangeInvoiceCategory = false;
      this.qualifyGroupInfo(true);
    } else {
      this.invoiceGroupData = null;
      this.canChangeInvoiceCategory = false;

      if (index == GroupQuoteCategory.GroupQuoteWithIndividualDetails) {
        this.isInvoiceGrouped = false;
        this.generateInvoiceNumber();
      }
    }
  }

  private qualifyGroupInfo(isNewGroupInfo: boolean) {
    this.invoiceGroupRef = this.dialogService.open(GroupInvoiceFormComponent, {
      closeOnEscape: false,
      closable: false,
      header: "Group Invoice Information",
      width: "40rem",
      contentStyle: { "min-height": "500px", overflow: "auto" },
      baseZIndex: 10000,
      data: {
        lead: this.currentLead,
        isNewGroupInfo: isNewGroupInfo,
        invoiceGroupData: this.invoiceGroupData,
      },
    });

    this.invoiceGroupRef.onClose.subscribe((res) => {
      if (res) {
        this.invoiceGroupData = res;
        if (isNewGroupInfo) {
          this.generateInvoiceNumber();
        }
      }
    });
  }

  updateGroupInfo() {
    var isNewInfo = this.invoiceGroupData?.contractStartDate ? false : true;
    this.qualifyGroupInfo(isNewInfo);
  }

  async selectContract(contract?: Contract) {
    if (
      !this.customerDivisionInView.sbuId ||
      this.fireStorageService.authUserProfile.sbuId !=
        this.customerDivisionInView.sbuId
    ) {
      if (this.customerDivisionInView.sbuId) {
        let secondarySbu = this.allSbuAttachments.find(
          (x) =>
            x.strategicBusinessUnitId == this.customerDivisionInView.sbuId &&
            x.attachedSBUId == this.fireStorageService.authUserProfile.id
        );
        if (!secondarySbu) {
          this.messageService.add({
            severity: "error",
            summary:
              "Sorry, Only this client attached SBU members can perform any endorsement action on this contract.",
          });
          this.ResetMessageToasters();
          return;
        }
      } else {
        this.messageService.add({
          severity: "error",
          summary:
            "Sorry, Only this client is not attached to any SBU so no endorsement action be taken.",
        });
        this.ResetMessageToasters();
        return;
      }
    }

    if (!contract.isActive) {
      this.messageService.add({
        severity: "error",
        summary:
          "Sorry, You can't perform any endorsement action on an inactive contract.",
      });
      this.ResetMessageToasters();
      return;
    }
    this.endorseContractClicked = false;
    this.endorseContractServiceClicked = false;
    this.selectedContract = contract;
    this.contractServices = contract.contractServices;
    this.contractIsSelected = true;
    this.includeExpiredContractService = false;
    this.hasSuccessfulValidation = false;
    this.RunViewReset(0);
    this.toggleExpiredContractService();
  }

  checkUniqueTag(event) {
    var tag = this.renewalForm.controls.uniqueTag?.value;
    //first check if it exist among the selected services for quote
    var isFoundInSelected: boolean = false;

    if (tag && tag?.length > 3) {
      if (tag == this.originalUniqueTag) return;

      // for (var quote of this.selectedQuoteService) {
      //   if (quote?.uniqueTag == tag) {
      //     this.flagNotUniqueError(tag);
      //     isFoundInSelected = true;
      //     break;
      //   }
      // }

      if (!isFoundInSelected) {
        this.contractServiceService.getContractServicesByTag(tag).subscribe(
          async (res: CommonResponse) => {
            if (res.responseCode == "00") {
              this.flagNotUniqueError(tag);
            }
          },
          async (error: any) => {
            //does not exist
            this.renewalForm.get("uniqueTag").setErrors(null);
          }
        );
      }
    }
  }

  private flagNotUniqueError(tag: string) {
    //value exists, indicate to user
    this.uniqueTagError = `The identifier '${tag}' has been taken`;
    this.renewalForm.get("uniqueTag").setErrors({ "not-unique": true });
  }

  viewContractServiceDetails(contractService: any) {
    // show more details about the contract service.
  }

  endorseContractService(contractService: any) {
    // console.log('Contract service clicked', contractService);
    this.RunViewReset(1);
    this.directAffectAdminNotice = "";

    if (
      contractService?.service?.serviceRelationshipEnum ==
      ServiceRelationshipEnum.Admin
    ) {
      this.canModifyQuantity = false;
      this.disableCancellation = true;
      this.messageService.add({
        severity: "info",
        summary: "Note",
        detail:
          "Value Change (on price) is the only endorsement allowed on admin service",
        closable: true,
      });
      this.ResetMessageToasters();
    } else {
      this.canModifyQuantity = true;
      this.disableCancellation = false;
    }

    if (
      contractService?.service?.serviceRelationshipEnum ==
      ServiceRelationshipEnum.Direct
    ) {
      this.shouldPairAdmin_CreditNote = true;
      //look for the matching admin
      this.adminPair = this.contractServices.find(
        (x) =>
          x.service?.serviceRelationshipEnum == ServiceRelationshipEnum.Admin &&
          x.adminDirectTie == contractService.adminDirectTie
      );
      this.directAffectAdminNotice = `Note: Changes in the quantity of this service would affect the admin service with tag: '${this.adminPair?.uniqueTag}'`;
      this.trackingAdminPrice = this.adminPair.unitPrice;
      this.setupAdminPricingRange(
        this.adminPair?.branchId,
        this.adminPair?.serviceId
      );
    } else {
      this.adminPair = null;
    }

    if (this.contractServiceUnderEndorsement !== contractService) {
      this.contractServiceUnderEndorsement = contractService;
      this.quantity = this.contractServiceUnderEndorsement.quantity;
      this.quantityTracker = this.contractServiceUnderEndorsement.quantity;

      this.getPossibleDates(contractService.id);
      this.setUpServicePricings(
        contractService.branchId,
        contractService.serviceId
      );
    }

    this.prefilForms();
    //this.setUpContractServiceRenewalTab(this.contractServiceUnderEndorsement)

    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  private prefilForms() {
    this.renewalForm.patchValue({
      discount: this.contractServiceUnderEndorsement?.discount,
      unitPrice: this.contractServiceUnderEndorsement?.unitPrice,
      quantity: this.contractServiceUnderEndorsement?.quantity,
      // endDate: this.contractServiceUnderEndorsement.contractEndDate,
      paymentCycle: this.paymentCycles.find(
        (x) => x.id == this.contractServiceUnderEndorsement.paymentCycle
      ),
      invoicingInterval: this.invoicingIntervals.find(
        (x) => x.id == this.contractServiceUnderEndorsement.invoicingInterval
      ),
    });

    this.renewalPriceQtyChanges();

    this.valueChangeForm.patchValue({
      discount: this.contractServiceUnderEndorsement.discount,
      unitPrice: this.contractServiceUnderEndorsement.unitPrice,
      billableAmount: this.contractServiceUnderEndorsement.billableAmount,
      vat: this.contractServiceUnderEndorsement.vat,
      endDate: this.contractServiceUnderEndorsement.contractEndDate,
      paymentCycle: this.paymentCycles.find(
        (x) => x.id == this.contractServiceUnderEndorsement.paymentCycle
      ),
      invoicingInterval: this.invoicingIntervals.find(
        (x) => x.id == this.contractServiceUnderEndorsement.invoicingInterval
      ),
    });
  }

  private setUpServicePricings(branchId: number, serviceId: number) {
    this.servicePricingService
      .getServicePricingsByServiceId(serviceId)
      .subscribe(async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          //ServicePricing[]
          const servicePricings = res.responseData ?? ([] as ServicePricing[]);

          let boundedQuantity = false;
          let min: number;
          let max: number;

          if (branchId && servicePricings.length > 0) {
            const servicePricing = servicePricings.find(
              (x) => x.branchId == branchId
            );
            if (servicePricing) {
              boundedQuantity = true;
              min = servicePricing.minimumAmount;
              max = servicePricing.maximumAmount;
            }
          }

          if (boundedQuantity) {
            this.valueChangeForm
              .get("unitPrice")
              .setValidators([
                Validators.required,
                Validators.min(min),
                Validators.max(max),
              ]);
            this.priceRangeErrorValueChange = `Unit price must be between ${min} and ${max}`;
            this.renewalForm
              .get("unitPrice")
              .setValidators([
                Validators.required,
                Validators.min(min),
                Validators.max(max),
              ]);
            this.priceRangeErrorRenewal = `Unit price must be between ${min} and ${max}`;
          }
        }
      });
  }

  setupAdminPricingRange(branchId: number, serviceId: number) {
    this.servicePricingService
      .getServicePricingsByServiceId(serviceId)
      .subscribe(async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          //ServicePricing[]
          const servicePricings = res.responseData ?? ([] as ServicePricing[]);
          let boundedQuantity = false;
          let min: number;
          let max: number;

          if (branchId && servicePricings.length > 0) {
            const servicePricing = servicePricings.find(
              (x) => x.branchId == branchId
            );
            if (servicePricing) {
              boundedQuantity = true;
              min = servicePricing.minimumAmount;
              max = servicePricing.maximumAmount;
            }
          }

          if (boundedQuantity) {
            this.minAdminPrice = min;
            this.maxAdminPrice = max;
          } else {
            this.minAdminPrice = null;
            this.maxAdminPrice = null;
          }
        } else {
          this.minAdminPrice = null;
          this.maxAdminPrice = null;
        }
      });
  }

  dragStart(service: Services) {
    this.draggedService = service;
  }

  private changeAdminPrice() {
    this.invoiceGroupRef = this.dialogService.open(AdminPriceComponent, {
      closeOnEscape: false,
      closable: false,
      header: "You can change admin unit price",
      width: "40rem",
      contentStyle: { "min-height": "200px", overflow: "auto" },
      baseZIndex: 10000,
      data: {
        price: this.adminPair?.unitPrice,
        minPrice: this.minAdminPrice,
        maxPrice: this.maxAdminPrice,
      },
    });

    this.invoiceGroupRef.onClose.subscribe((res) => {
      if (res) {
        this.adminPair.unitPrice = res.unitPrice;
      }

      this.processAllEndorsement(this.renewalForm, 3);
    });
  }

  verifyDate() {
    const serviceValue = this.renewalForm.value;
    const contractStart = new Date(serviceValue.startDate);
    const contractEnd = new Date(serviceValue.endDate);

    //this.minStartDate = new Date(minStartDate.setDate(minStartDate.getDate() + interval));
    this.minStartDate = new Date(
      this.contractServiceUnderEndorsement?.contractEndDate
    );
    this.minStartDate.setDate(this.minStartDate?.getDate() + 1);

    this.minEndDate = new Date(this.minStartDate);
    this.minEndDate.setDate(this.minStartDate?.getDate() + 1);

    if (contractStart > contractEnd) {
      this.renewalForm
        .get("startDate")
        .setErrors({ "contract-start-error": true });
    } else {
      this.renewalForm.get("endDate").setErrors(null);
    }
  }

  private getPossibleDates(contractServiceId) {
    this.endorsementService
      .getEndorsementPossibleStartDates(contractServiceId)
      .subscribe(
        async (res: CommonResponse) => {
          this.possibleStartDates = res.responseData;
          this.verifyDate();
        },
        (error) => {
          this.connectionError();
        }
      );
  }

  resetAdditionEndorsement() {
    this.additionContractChoice = null;
    this.canChangeInvoiceCategory = true;
    this.selectedCategory = this.categories[0];
    this.invoiceGroupData = null;
  }

  modifyContract() {
    this.RunViewReset(2);
    this.resetAdditionEndorsement();
    this.setUpServiceAdditionTab();
    this.activeTabIndex = 0;
    this.fetchNewAdditionContract();
    this.formWrapper.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "start",
    });
  }

  newContractSetup() {
    this.selectedCategory = this.categories[0];
  }

  async setUpServiceAdditionTab() {
    console.log("Setting up Contract [Service Addition]");
    this.selectedQuoteService = [];
    this.selectedGeneralService = [];
    this.selectedServices = [];

    this.selectedCategory = this.selectedContract.groupContractCategory;

    if (
      this.selectedContract.groupContractCategory ==
      GroupContractCategory.GroupContractWithSameDetails
    ) {
      this.isInvoiceGrouped = true;
      await this.setGroupInvoiceInfo();
    } else {
      this.isInvoiceGrouped = false;
      this.invoiceGroupData = null;
    }
  }

  async setGroupInvoiceInfo() {
    //use the first quote service to set the info for this invoiceGroupData
    let contractService = this.contractServices[0];
    this.invoiceGroupData = {
      fulfillmentStartDate: contractService.fulfillmentStartDate,
      fulfillmentEndDate: contractService.fulfillmentEndDate,
      contractStartDate: contractService.contractStartDate,
      contractEndDate: contractService.contractEndDate,
      activationDate: contractService.activationDate,
      paymentCycle: contractService.paymentCycle,
      invoicingInterval: contractService.invoicingInterval,
      firstInvoiceSendDate: contractService.firstInvoiceSendDate,
    };

    //get the possible start dates for this contract service
    this.getPossibleDates(contractService.id);
  }

  setUpServiceRenewalTab() {
    console.log("Setting up Contract [Service Renewal]");
    this.isInvoiceGrouped = false;
    this.invoiceGroupData = null;
    this.qualifiableContractServices = [];

    if (this.contractIsDueForRenewal) {
      this.contractServices.forEach((contractService) => {
        this.qualifiableContractServices = [
          ...this.qualifiableContractServices,
          {
            service: contractService.service,
            quoteService: JSON.parse(JSON.stringify(contractService)),
            toBeRenewed: true,
          },
        ];
      });
    }
  }

  processRenewalEndorsement() {
    if (
      this.contractServiceUnderEndorsement?.service?.serviceRelationshipEnum ==
      ServiceRelationshipEnum.Admin
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Cancelled",
        detail: "Cannot renew on an admin contract service",
      });
      this.ResetMessageToasters();
      return;
    }

    this.renewalIsSubmitted = true;

    this.renewalForm.patchValue({
      endorsementEffect: "retention",
    });

    if (this.renewalForm.invalid) {
      this.messageService.add({
        severity: "error",
        summary: "Cancelled",
        detail: "Some required inputs are missing",
      });
      this.ResetMessageToasters();
      return;
    }

    if (this.uploadedDocuments.length == 0) {
      return;
    }

    if (this.adminPair) {
      this.changeAdminPrice();
    } else {
      this.processAllEndorsement(this.renewalForm, 3);
    }
  }

  renewalPriceQtyChanges() {
    this.calculateBillableVat(this.renewalForm);
  }

  valueChangePriceQtyChanges() {
    this.calculateBillableVat(this.valueChangeForm);
  }

  calculateBillableVat(formGroup: FormGroup) {
    var service = this.contractServiceUnderEndorsement?.service;
    const unitPrice = formGroup.get("unitPrice").value ?? service.unitPrice;
    const quantity = formGroup.get("quantity").value ?? 1;
    const _vatControl = formGroup.get("vat");
    const _billableControl = formGroup.get("billableAmount");
    let _discount = formGroup.get("discount").value ?? 0;
    if (_discount > 100) {
      _discount = 100;
      formGroup.get("discount").setValue(100);
    }
    const totalPrice = unitPrice * quantity;

    let vat: number;
    if (service.isVatable) {
      vat = parseFloat((totalPrice * 0.075).toFixed(2));
    } else {
      vat = 0;
    }

    const billable = totalPrice - (_discount / 100) * totalPrice + vat;
    _billableControl.setValue(billable);
    formGroup.get("budget").setValue(billable);
    _vatControl.setValue(vat);

    if (billable > this.contractServiceUnderEndorsement.billableAmount) {
      formGroup.get("endorsementEffect").setValue("Service TopUp");
    } else if (billable < this.contractServiceUnderEndorsement.billableAmount) {
      formGroup.get("endorsementEffect").setValue("Service Reduction");
    } else {
      formGroup.get("endorsementEffect").setValue("NO_CHANGE");
    }
  }

  processValueChangeEndorsement() {
    this.valueChangeIsSubmitted = true;
    this.valueChangeForm.patchValue({
      startDate: this.selectedStartDate?.startDate,
      // endDate: "",
    });

    if (
      this.valueChangeForm
        .get("endorsementEffect")
        .value?.toLowerCase()
        ?.includes("no")
    ) {
      return;
    }
    this.processAllEndorsement(this.valueChangeForm, 1);
  }

  private processAllEndorsement(formGroup: FormGroup, id: number) {
    if (!this.hasSuccessfulValidation) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "You need a successful contract validation to proceed, Kindly make use of the 'Check Contract Validity' button.",
      });
      this.ResetMessageToasters();
      return;
    }

    this.isrequired = true;
    if (formGroup && formGroup?.invalid) {
      this.messageService.add({
        severity: "error",
        summary: "Cancelled",
        detail: "Some required inputs are missing",
      });
      this.validatingAddition = false;
      this.ResetMessageToasters();
      return;
    }

    //check that there is document upload
    if (this.uploadedDocuments.length == 0) {
      this.validatingAddition = false;
      return;
    }

    this.confirmationService.confirm({
      message: "Are you sure you want to process this endorsement ?",
      accept: () => {
        this.documentUrls = [];
        if (this.uploadedDocuments.length > 0) {
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.uploadedDocuments
          );

          this.fileStorageService.onUploadFinished.subscribe(
            (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
                this.ResetMessageToasters();
              } else {
                this.documentUrls = resp.responseData;
                if (id == 4) {
                  this._processAdditionEndorsement();
                } else {
                  this._processAllEndorsement(formGroup, id);
                }
              }
            },
            (error) => {
              console.log("Error while creating platform " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image to storage",
              });
              this.ResetMessageToasters();
            }
          );
        } else {
          if (id == 4) {
            this._processAdditionEndorsement();
          } else {
            this._processAllEndorsement(formGroup, id);
          }
        }
      },
      reject: () => {
        this.messageService.add({
          severity: "error",
          summary: "Cancelled",
          detail: "Endorsement Cancelled",
        });
      },
    });
  }

  private _processAllEndorsement(formGroup: FormGroup, id: number) {
    let postData = [];

    const unitPrice = formGroup.get("unitPrice").value;
    const quantity = formGroup.get("quantity").value;
    const endorsementDescription = formGroup.get("description").value;

    const contractService = this.contractServiceUnderEndorsement;
    var endorsement = formGroup.get("endorsementEffect").value;

    contractService.unitPrice = unitPrice;
    contractService.quantity = quantity;
    contractService.vat = formGroup.get("vat").value;
    contractService.billableAmount = formGroup.get("billableAmount").value;
    contractService.budget = formGroup.get("budget").value;
    console.log(contractService.adminDirectTie);

    contractService.invoicingInterval =
      formGroup.get("invoicingInterval")?.value?.id;
    contractService.paymentCycle = formGroup.get("paymentCycle")?.value?.id;

    // contractService.uniqueTag = formGroup.get("uniqueTag").value;
    contractService.uniqueTag =
      formGroup.get("uniqueTag").value == ""
        ? contractService.uniqueTag
        : formGroup.get("uniqueTag").value == "";

    contractService.endorsementDescription = endorsementDescription;
    const endorsementTypeId = this.endorsementTypes.find((x) =>
      x.caption.toLowerCase().includes(endorsement.toLowerCase())
    ).id;

    contractService.endorsementTypeId = endorsementTypeId;
    contractService.isRequestedForApproval = true;
    contractService.isApproved = false;
    contractService.isDeclined = false;
    contractService.previousContractServiceId = contractService.id;
    contractService.dateForNewContractToTakeEffect =
      formGroup.get("startDate").value;
    contractService.contractStartDate = formGroup.get("startDate").value;
    contractService.contractEndDate = formGroup.get("endDate").value;

    // Move branch and office from lead division to customer division
    contractService.contractId = this.selectedContract.id;
    contractService.branchId = this.contractServiceUnderEndorsement?.branchId;
    contractService.officeId = this.contractServiceUnderEndorsement?.officeId;
    contractService.customerDivisionId = this.customerDivisionInformation.id;
    contractService.documentUrl = this.documentUrls.join(",");

    //check if this is direct and effect quanity change on corresponding admin
    if (
      contractService?.service?.serviceRelationshipEnum ==
      ServiceRelationshipEnum.Direct
    ) {
      //check if the quanity changed
      if (this.quantityTracker != quantity || id == 3) {
        //look for the matching admin
        if (this.adminPair) {
          let modifiedAdmin = this.prepareAdminContractServiceForPosting(
            contractService,
            this.adminPair
          );
          if (contractService.uniqueTag)
            modifiedAdmin.uniqueTag = contractService.uniqueTag;
          else contractService.uniqueTag = modifiedAdmin.uniqueTag;
          postData.push(modifiedAdmin);
        }
      }
    }

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Setting up endorsement for approvals.",
    });
    this.ResetMessageToasters();
    postData.push(contractService);

    this.endorsementService.postEndorsement(postData).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail:
              "Endorsement processed and sent for approvals successfully.",
          });
          this.ResetMessageToasters();
          this.uploadedDocuments = [];
          this.resetFormSubmitted(id);
          formGroup.reset();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: `${res.responseMsg}`,
          });
          this.ResetMessageToasters();
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `${error.error.message}`,
        });
        this.ResetMessageToasters();
      }
    );
  }

  private resetFormSubmitted(id: number) {
    if (this.fileUploader) {
      this.fileUploader.clear();
    }

    if (id == 1) {
      this.valueChangeIsSubmitted = false;
    } else if (id == 2) {
      this.cancelFormIsSubmitted = false;
    } else if (id == 3) {
      this.renewalIsSubmitted = false;
    }
  }

  private prepareAdminContractServiceForPosting(direct: any, admin: any) {
    admin.endorsementDescription = direct.endorsementDescription;
    admin.endorsementTypeId = direct.endorsementTypeId;
    admin.isRequestedForApproval = true;
    admin.customerDivisionId = direct.customerDivisionId;
    admin.isApproved = false;
    admin.isDeclined = false;
    admin.quantity = direct.quantity;
    admin.previousContractServiceId = admin.id;
    admin.contractId = direct.contractId;
    admin.documentUrl = direct.documentUrl;
    admin.dateForNewContractToTakeEffect =
      direct.dateForNewContractToTakeEffect;
    admin.contractEndDate = direct.contractEndDate;
    admin.contractStartDate = direct.contractStartDate;
    admin.invoicingInterval = direct.invoicingInterval;
    admin.paymentCycle = direct.paymentCycle;
    // admin.uniqueTag = this.adminPair.uniqueTag + " - Renewal";
    admin.uniqueTag = this.adminPair.uniqueTag;

    return this.getAdminAfterCalculation(admin);
  }

  private getAdminAfterCalculation(admin: any) {
    const unitPrice = this.adminPair.unitPrice; //admin.unitPrice;
    const quantity = admin.quantity;
    let _discount = admin.discount ?? 0;
    if (_discount > 100) {
      _discount = 100;
      admin.discount = 100;
    }

    const totalPrice = unitPrice * quantity;

    let vat: number;
    if (admin?.service.isVatable) {
      vat = parseFloat((totalPrice * 0.075).toFixed(2));
    } else {
      vat = 0;
    }

    const billable = totalPrice - (_discount / 100) * totalPrice + vat;
    admin.billableAmount = billable;
    admin.budget = billable;
    admin.vat = vat;
    return admin;
  }

  processServiceCancellationEndorsement() {
    if (
      this.contractServiceUnderEndorsement?.service?.serviceRelationshipEnum ==
      ServiceRelationshipEnum.Admin
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Cancelled",
        detail: "Cannot cancel an admin contract service",
      });
      this.ResetMessageToasters();
      return;
    }
    this.serviceCancelForm.patchValue({
      quantity: 0,
      vat: 0,
      billableAmount: 0,
      budget: 0,
      startDate: this.selectedStartDate?.startDate,
      endDate: "",
      endorsementEffect: "Service Reduction",
      unitPrice: this.contractServiceUnderEndorsement.unitPrice,
    });

    this.processAllEndorsement(this.serviceCancelForm, 2);
  }

  refreshTabsData(event) {
    // console.log('the index ', event.index)

    if (event.index === 0) {
      if (this.endorseContractClicked) this.setUpServiceAdditionTab();
    } else if (event.index === 1) {
      if (this.endorseContractClicked) this.setUpServiceRenewalTab();
    } else {
      console.log("Unrecognised event");
    }
  }

  setUpForms() {
    this.clientForm = this.formBuilder.group({
      customerId: [null, Validators.required],
    });

    this.valueChangeForm = this.formBuilder.group({
      unitPrice: ["", [Validators.required, Validators.min(1)]],
      quantity: ["", [Validators.required, Validators.min(1)]],
      startDate: [null, Validators.required],
      endDate: [""],
      description: ["", [Validators.required, Validators.minLength(10)]],
      discount: [0, Validators.required],
      vat: [0, Validators.required],
      billableAmount: ["", Validators.required],
      budget: ["", Validators.required],
      endorsementEffect: ["NO_CHANGE"],
      uniqueTag: [""],
      invoicingIntervals: [""],
      paymentCycle: [""],
    });

    this.serviceCancelForm = this.formBuilder.group({
      startDate: [null, Validators.required],
      endDate: [""],
      description: ["", [Validators.required, Validators.minLength(10)]],
      unitPrice: [""],
      quantity: ["", Validators.required],
      discount: [0, Validators.required],
      vat: [0, Validators.required],
      billableAmount: ["", Validators.required],
      budget: ["", Validators.required],

      endorsementEffect: ["SERVICE CANCELLATION"],
      uniqueTag: [""],
    });

    this.renewalForm = this.formBuilder.group({
      uniqueTag: ["", Validators.required],
      description: ["", Validators.required],
      unitPrice: ["", Validators.required],
      quantity: ["", Validators.required],
      discount: [0, Validators.required],
      vat: [0, Validators.required],
      billableAmount: [0, Validators.required],
      budget: [0, Validators.required],
      startDate: ["", Validators.required],
      endDate: ["", Validators.required],
      endorsementEffect: ["SERVICE RENEWAL"],
      paymentCycle: [""],
      invoicingInterval: [""],
    });

    this.edForm = this.formBuilder.group({
      startDate: [null, Validators.required],
      description: ["", [Validators.required, Validators.minLength(10)]],
    });

    this.sampleForm = this.formBuilder.group({
      description: ["", [Validators.required, Validators.minLength(10)]],
    });
  }

  private rangeDate(intervalId: number) {
    switch (intervalId) {
      case 0:
        return 7;
      case 1:
        return 3;
      case 2:
        return 30;
      case 3:
        return 15;
      case 4:
        return 90;
      case 5:
        return 180;
      case 6:
        return 365;
      case 7:
        return 180;
      default:
        return 30;
    }
  }

  toggleRenewalStatus(qualifiableContractService: any) {
    qualifiableContractService.toBeRenewed =
      !qualifiableContractService.toBeRenewed;
  }

  invalidRenewal(): boolean {
    return (
      this.qualifiableContractServices.find((x) => x.toBeRenewed).length == 0
    );
  }

  async loadServices() {
    await this.servicesService.allService().subscribe(async (res: any) => {
      this.loadingService = false;
      if (res.responseCode == "00") this.services = res.responseData;
    });
  }

  async loadDivisions() {
    await this.divisionService.allDivisionData().subscribe(
      async (r) => {
        var res = r.responseData;
        if (res) {
          if (res.length > 0) {
            res.forEach((value, key) => {
              const operationEntities = value.operatingEntities;
              const operationEntityTree = [];
              if (operationEntities.length > 0) {
                operationEntities.forEach((valueA, keyA) => {
                  const serviceGroups = valueA.serviceGroups;
                  const serviceGroupsTree = [];
                  if (serviceGroups.length > 0) {
                    serviceGroups.forEach((value1, key2) => {
                      const groupCategory = value1.serviceCategories;
                      const serviceCategoriesTree = [];
                      if (groupCategory.length > 0) {
                        groupCategory.forEach((valueC, keyC) => {
                          serviceCategoriesTree.push({
                            key: valueC.id,
                            label: valueC.name,
                            icon: "pi pi-fw pi-file",
                            data: valueC.description,
                            selectable: true,
                          });
                        });
                      }
                      serviceGroupsTree.push({
                        label: value1.name,
                        data: value1.description,
                        expandedIcon: "pi pi-minus-circle",
                        collapsedIcon: "pi pi-plus-circle",
                        leaf: false,
                        children: serviceCategoriesTree,
                      });
                    });
                  }
                  operationEntityTree.push({
                    label: valueA.name,
                    data: valueA.description,
                    expandedIcon: "pi pi-minus-circle",
                    collapsedIcon: "pi pi-plus-circle",
                    leaf: false,
                    children: serviceGroupsTree,
                  });
                });
              }
              this.serviceTreeChildren.push({
                label: value.name,
                data: value.description,
                expandedIcon: "pi pi-minus-circle",
                collapsedIcon: "pi pi-plus-circle",
                leaf: false,
                children: operationEntityTree,
              });
            });
          }
        }
        this.loadingServices = false;
      },
      (error) => {
        this.loadingServices = false;
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection failed",
        });
        this.ResetMessageToasters();
      }
    );
  }

  getEndorsementTypes() {
    this.endorsementTypeService.allEndorsementTypeData().subscribe(
      async (res: CommonResponse) => {
        this.endorsementTypes = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
        this.ResetMessageToasters();
      }
    );
  }

  getCustomerDivisions() {
    this.customerDivisionService.allCustomerDivision().subscribe(
      async (res: CommonResponse) => {
        this.customerDivisions = res.responseData;
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: "Connection error, Try again later",
        });
        this.ResetMessageToasters();
      }
    );
  }

  searchContractServicesByClient() {
    this.customerDivisionInView = this.selectedCustomerDivision;
    this.resetClientSpecificInfo();
    this.getCustomerDivisionById(this.selectedCustomerDivision.id);
  }

  fetchClient() {
    // if(this.selectedCustomerDivision?.id)
    // {
    //     if(this.selectedDivisionIdTracking != this.selectedCustomerDivision?.id)
    //     {
    //         this.selectedDivisionIdTracking = this.selectedCustomerDivision.id
    //         this.resetClientSpecificInfo();
    //         this.getCustomerDivisionById(this.selectedCustomerDivision.id);
    //     }
    // }
  }

  resetClientSpecificInfo() {
    this.customerDivisionInformation = null;
    this.selectedContract = null;
    this.contracts = null;
    this.viewedContractService = null;
    this.endorseContractClicked = false;
    this.endorseContractServiceClicked = false;
    this.contractServiceUnderEndorsement = null;
  }

  getCustomerDivisionById(customerDivisionId: any) {
    this.fetchingContractServices = true;
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Retrieving Client Information",
    });
    this.ResetMessageToasters();

    this.customerDivisionService
      .getCustomerDivision(customerDivisionId)
      .subscribe(
        async (r: CommonResponse) => {
          await this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Retrieved Client Information Successfully",
          });
          this.ResetMessageToasters();
          var result = r.responseData;
          this.customerDivisionInformation = result;
          const primaryContact =
            this.customerDivisionInformation.primaryContact;
          const secondaryContact =
            this.customerDivisionInformation.secondaryContact;

          this.primaryContactFullName = "Not Available.";
          this.secondaryContactFullName = "Not Available.";

          if (primaryContact)
            this.primaryContactFullName = `${primaryContact.firstName} ${primaryContact.lastName}`;
          if (secondaryContact)
            this.secondaryContactFullName = `${secondaryContact.firstName} ${secondaryContact.lastName}`;

          this.contracts = result.contracts;
          this.fetchingContractServices = false;
        },
        (error) => {
          this.connectionError();
          this.fetchingContractServices = false;
        }
      );
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
    this.ResetMessageToasters();
  }

  async GetAllServiceCategories() {
    this.serviceCategoryService
      .GetAllServiceCategoriesWithRelationship()
      .subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            this.ResetMessageToasters();
            return;
          }

          this.allServiceCategories = data.responseData;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all service categories at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
          this.ResetMessageToasters();
        }
      );
  }

  showService(event) {
    if (event.node.key && this.allServiceCategories.length > 0) {
      this.availableServices = null;
      this.loadingServicesCategory = true;
      let serviceCategory = this.allServiceCategories.find(
        (x) => x.id == event.node.key
      );
      if (serviceCategory) {
        this.availableServices = [];
        this.selectedCategories = serviceCategory;
        this.availableServices = serviceCategory.services;
        this.loadingServicesCategory = false;
      }
    } else this.showService__retired(event);
  }

  showService__retired(event) {
    if (event.node.key) {
      this.availableServices = null;
      this.loadingServicesCategory = true;
      this.serviceCategoryService
        .getCategory(event.node.key)
        .subscribe(async (res: CommonResponse) => {
          if ((res.responseCode = "00")) {
            this.availableServices = [];
            const categories = res.responseData;
            this.selectedCategories = categories;
            this.availableServices = categories.services;
            this.loadingServicesCategory = false;
          }
        });
    }
  }

  onUpload(event) {
    this.uploadedDocuments = [];
    for (const file of event.files) {
      this.uploadedDocuments.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Document(s) Uploaded",
    });
    this.ResetMessageToasters();
  }

  selectFile(event, uploader: FileUpload) {
    this.fileUploader = uploader;
    this.onUpload(event);
  }

  onCancelUpload() {
    this.uploadedDocuments = [];
  }

  fetchBranches() {
    this.branchService.allBranch().subscribe(
      async (result: CommonResponse) => {
        if (result.responseCode == "00") {
          this.branches = result.responseData;
          this.branches.forEach((branch) => {
            branch.offices.forEach((office) => {
              var entry = {
                name: `${office.name}/${branch.name}`,
                branchId: branch.id,
                officeId: office.id,
              };
              this.offices.push(entry);
            });
          });
        }
      },
      (error) => {
        this.connectionError();
      }
    );
  }

  drop() {
    if (this.draggedService) {
      if (!this.additionContractChoice) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail: "You have not indicated the addition contract option",
        });
        this.ResetMessageToasters();
        return;
      }

      if (
        this.additionContractChoice == 2 &&
        this.selectedCategory?.key == "0"
      ) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail: "You have not selected a category",
        });
        this.ResetMessageToasters();
        return;
      }
      var taskCount = this.serviceCategoryTasks.filter(
        (x) =>
          x.serviceCategory.id == this.draggedService.serviceCategoryId &&
          x.endorsementType?.caption === "Service Addition"
      ).length;

      if (taskCount < 1) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail:
            "Service category on this service does not have category task(s) for endorsement type 'Service Addition'",
        });
        this.ResetMessageToasters();
        return;
      }

      //check if this service can be added
      var [canAddThisService, message, matchingDirect] =
        this.canAddWithPossibleDirectMatch(this.draggedService);
      if (!canAddThisService) {
        this.messageService.add({
          severity: "error",
          summary: "Canceled",
          detail: message,
        });
        this.ResetMessageToasters();
        return;
      } else {
        this.qualify(
          true,
          this.offices,
          this.draggedService,
          matchingDirect,
          null
        );
      }

      this.calTotalService();
      this.draggedService = null;
    }
  }

  editQuoteService(quoteService: QuoteServices) {
    this.qualify(false, this.offices, null, null, quoteService);
  }

  dragEnd() {
    this.draggedService = null;
  }

  async remove(quote: QuoteServices) {
    const index = this.selectedQuoteService.findIndex(
      (x) => x.tempId == quote.tempId
    );

    if (index == -1) {
      this.messageService.add({
        severity: "error",
        summary: "Error",
        detail: "Service does not exist in the list",
      });
      this.ResetMessageToasters();
      return;
    }

    //check if this is a direct and has an admin
    if (quote.serviceRelationshipEnum == ServiceRelationshipEnum.Direct) {
      var [hasAdmin, theAdmin] = this.thisDirectHasAnAdmin(quote);
      if (hasAdmin) {
        this.messageService.add({
          severity: "error",
          summary: "Cannot Remove",
          detail: `First remove the admin with tag '${theAdmin?.uniqueTag}' before this direct'`,
        });
        return;
      }
    }

    if (quote.serviceRelationshipEnum == ServiceRelationshipEnum.Admin) {
      //check if there is no handing direct waiting to be paired to an Admin
      var allDirects = this.selectedQuoteService?.filter(
        (p) => p.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
      );

      let shouldReturn: boolean = false;
      for (var thisDirect of allDirects) {
        var [hasAdmin, theAdmin] = this.thisDirectHasAnAdmin(thisDirect);
        if (!hasAdmin) {
          this.messageService.add({
            severity: "error",
            summary: "Cannot Remove",
            detail: `You have an unpaired direct with tag '${thisDirect?.uniqueTag}'. Please remove it or pair it`,
          });
          shouldReturn = true;
          break;
        }
      }

      if (shouldReturn) return;
    }

    this.selectedQuoteService = this.selectedQuoteService.filter(
      (val, i) => i !== index
    );
    this.draggedService = null;
    this.messageService.add({
      severity: "success",
      summary: "Removed",
      detail: "Service Removed",
    });

    await this.calTotalService();
  }

  //determin if this service can be added to the quote and return the quote service
  canAddWithPossibleDirectMatch(
    service: Services
  ): [boolean, string, QuoteServices] {
    if (service.serviceRelationshipEnum == ServiceRelationshipEnum.Admin) {
      let directServiceId = service?.adminRelationship?.directServiceId;
      //find all its matching directs
      var matchingDirects = this.selectedQuoteService.filter(
        (x) => x.serviceId == directServiceId
      );
      //loop through this array and check if all are paired; return the quotesevice not paired
      for (var x of matchingDirects) {
        //check if this direct has an Admin tie in the selectedQuoteService
        var thisAdminMatch = this.selectedQuoteService?.find(
          (p) =>
            p.adminDirectTie == x.adminDirectTie &&
            p.serviceRelationshipEnum == ServiceRelationshipEnum.Admin
        );
        if (!thisAdminMatch) {
          return [true, "Can add this admin service", x];
        }
      }

      //all the directs have been paired or there is no direct in the selection
      return [
        false,
        "Please first select a preceding direct before this admin",
        null,
      ];
    } else if (
      service.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
    ) {
      return this.hasNoUnpairedDirects();
    } else {
      return [true, "Can add this singleton service", null];
    }
  }

  hasNoUnpairedDirects(): [boolean, string, QuoteServices] {
    //check if there is a hanging direct waiting to be paired to an admin
    var allDirects = this.selectedQuoteService?.filter(
      (x) => x.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
    );
    for (var x of allDirects) {
      //foreach
      //return this.thisDirectHasAnAdmin(x);
      var [hasAnAdmin, theAdmin] = this.thisDirectHasAnAdmin(x);
      if (!hasAnAdmin) {
        var nameOfUnmatched = this.services?.find(
          (p) => p.id == x.serviceId
        )?.name;
        return [
          false,
          `You need an Admin for the direct service ${nameOfUnmatched}`,
          null,
        ];
      }
    }

    return [true, "Can add this another service", null];
  }

  private thisDirectHasAnAdmin(x: QuoteServices): [boolean, QuoteServices] {
    //check if this direct has an Admin tie in the selectedQuoteService
    var theAdminMatch = this.selectedQuoteService?.find(
      (p) =>
        p.adminDirectTie == x.adminDirectTie &&
        p.serviceRelationshipEnum == ServiceRelationshipEnum.Admin
    );
    if (!theAdminMatch) {
      return [false, null];
    }
    return [true, theAdminMatch];
  }

  async calTotalService() {
    this.billableAmountTotal = 0;
    this.selectedQuoteService.forEach((x, i) => {
      this.billableAmountTotal += x.billableAmount;
    });
  }

  async qualify(
    _isNewQuoteService: boolean,
    _branches: Office[],
    service?: Services,
    _matchingDirect?: QuoteServices,
    _quoteServiceToEdit?: QuoteServices
  ) {
    let data;
    let selectedQuoteServicesToPass;
    if (!_isNewQuoteService) {
      //exclude the _quoteServiceToEdit from the list
      selectedQuoteServicesToPass = this.selectedQuoteService.filter(function (
        x
      ) {
        return x.uniqueTag !== _quoteServiceToEdit.uniqueTag;
      });
      if (!service) {
        if (!_quoteServiceToEdit) {
          await this.messageService.add({
            severity: "error",
            summary: "Strange behaviour. No service to add!!!",
            detail: service.name,
          });
          this.ResetMessageToasters();
          return;
        } else {
          service = this.services.find(
            (x) => x.id == _quoteServiceToEdit.serviceId
          );
        }
      }
    }

    var includeSampleRecord =
      this.selectedContract.groupContractCategory ==
      GroupContractCategory.GroupContractWithIndividualDetails;

    data = {
      groupInvoices: this.isInvoiceGrouped,
      services: service,
      quoteServiceList: _isNewQuoteService
        ? this.selectedQuoteService
        : selectedQuoteServicesToPass,
      matchingDirect: _matchingDirect, //for adding matching Admin to have same values
      quoteService: _quoteServiceToEdit, //for update
      branches: _branches,
      isNewQuoteService: _isNewQuoteService,
      startDates:
        this.isInvoiceGrouped && this.additionContractChoice == 1
          ? this.possibleStartDates
          : null,
      quoteCategoryLevel: this.selectedContract.groupContractCategory,
      firstRecordAsSample: includeSampleRecord
        ? this.contractServices[0]
        : null,
      isNotRecurringInvoicing:
        this.contractServices[0]?.invoicingInterval == 5 ||
        this.contractServices[0]?.invoicingInterval == 6,
    };

    this.qualifyRef = this.dialogService.open(ServiceQualifyComponent, {
      header: "Qualify " + service.name,
      width: "70%",
      contentStyle: {
        "min-height": "500px",
        height: "100vh",
        overflow: "auto",
      },
      baseZIndex: 10000,
      data,
    });

    this.qualifyRef.onClose.subscribe(async (res: any) => {
      if (res) {
        const _selectedQuoteService = res;
        //_selectedQuoteService.referenceNumber = this.currentLead.referenceNo;

        if (_isNewQuoteService) {
          if (_selectedQuoteService) {
            //add extra details to this quote service for identification
            _selectedQuoteService.serviceId = service.id;
            _selectedQuoteService.serviceRelationshipEnum =
              service.serviceRelationshipEnum;
            _selectedQuoteService.serviceName = service.name;
            _selectedQuoteService.tempId = ++this.tempIdAssignment;

            this.selectedQuoteService = [
              ...this.selectedQuoteService,
              _selectedQuoteService,
            ];

            await this.messageService.add({
              severity: "success",
              summary: "Service Qualified, Press save to effect the changes",
              detail: service.name,
            });

            //cannot change the invoice category for again
          }
        } else {
          //find the index of the quoteservice
          var index = this.selectedQuoteService.findIndex(
            (x) => x.tempId === _quoteServiceToEdit.tempId
          );
          //assign service name, tempId back to the edited quote service
          _selectedQuoteService.serviceName = _quoteServiceToEdit.serviceName;
          _selectedQuoteService.tempId = _quoteServiceToEdit.tempId;
          this.selectedQuoteService[index] = _selectedQuoteService;

          if (
            _selectedQuoteService.serviceRelationshipEnum ==
            ServiceRelationshipEnum.Direct
          ) {
            //update the corresponding admin if exists
            this.updateMatchingAdmin(_selectedQuoteService);
          }

          await this.messageService.add({
            severity: "success",
            summary: "Edit Sucesss",
            detail: "The quote service has been edited",
          });
        }
      }

      // if(!type){
      //   console.log(res)
      //   Object.assign(_services.quoteService, _services.quoteService, res)
      // }

      await this.calTotalService();
    });
  }

  private updateMatchingAdmin(direct: QuoteServices) {
    //check if this has an admin
    let [hasAdmin, admin] = this.thisDirectHasAnAdmin(direct);
    if (hasAdmin) {
      //change corresponding values of the admin
      admin.quantity = direct.quantity;
      admin.branchId = direct.branchId;
      admin.contractEndDate = direct.contractEndDate;
      admin.contractStartDate = direct.contractStartDate;
      admin.firstInvoiceSendDate = direct.firstInvoiceSendDate;
      admin.fulfillmentEndDate = direct.fulfillmentEndDate;
      admin.fulfillmentStartDate = direct.fulfillmentStartDate;
      admin.activationDate = direct.activationDate;
      admin.paymentCycle = direct.paymentCycle;
      admin.invoicingInterval = direct.invoicingInterval;
      //admin.discount = direct.discount;

      //find the index of the admin in the selectedQuoteService
      let index = this.selectedQuoteService.findIndex(
        (x) =>
          x.adminDirectTie == admin.adminDirectTie &&
          x.serviceRelationshipEnum == ServiceRelationshipEnum.Admin
      );
      if (index > -1) {
        //now first the service that the quote service matches
        let service = this.services.find((x) => x.id == admin.serviceId);

        //calculate the billable vat on this admin again
        var reCalculatedAdmin = this.calculateBillableVatAdmin(admin, service);
        this.selectedQuoteService[index] = reCalculatedAdmin;
      }
    }
  }

  calculateBillableVatAdmin(
    admin: QuoteServices,
    service: Services
  ): QuoteServices {
    const unitPrice = admin.unitPrice;
    const quantity = admin.quantity;
    let _discount = admin.discount ?? 0;
    if (_discount > 100) {
      _discount = 100;
      admin.discount = 100;
    }
    const totalPrice = unitPrice * quantity;

    let vat: number;
    if (service.isVatable) {
      vat = parseFloat((totalPrice * 0.075).toFixed(2));
    } else {
      vat = 0;
    }

    const billable = totalPrice - (_discount / 100) * totalPrice + vat;
    admin.billableAmount = billable;
    admin.budget = billable;
    admin.vat = vat;
    return admin;
  }

  private prepareQuoteData() {
    const _quoteServices: ContractService[] = [];
    if (this.isInvoiceGrouped) {
      if (this.invoiceGroupData) {
        this.selectedQuoteService.forEach((value) => {
          var startDate = value.contractStartDate;
          const valueNew = {
            ...value,
            ...this.invoiceGroupData,
            invoicingInterval: this.invoiceGroupData.invoicingInterval,
            paymentCycle: this.invoiceGroupData.paymentCycle,
          };
          if (startDate) valueNew.contractStartDate = startDate;
          _quoteServices.push(valueNew);
        });
      }
    }
    return _quoteServices;
  }

  private _processAdditionEndorsement() {
    this.isrequired = true;
    if (this.isInvoiceGrouped && !this.invoiceGroupData) {
      this.messageService.add({
        severity: "error",
        summary: "Failure",
        detail: `Group data not specified. Please update group data`,
      });
      this.validatingAddition = false;
      this.ResetMessageToasters();
      return;
    }

    let postData = [];
    if (this.isInvoiceGrouped) {
      const _quoteServices: any[] = this.prepareQuoteData();
      postData = _quoteServices;
    } else {
      this.matchAdminWithDirectDates();
      postData = this.selectedQuoteService;
    }

    const endorsementTypeId = this.endorsementTypes.find((x) =>
      x.caption.toLowerCase().includes("addition")
    ).id;
    var contractId =
      this.additionContractChoice == 1 ? this.selectedContract.id : 0;
    var groupContractCategory = this.selectedCategory?.key;
    var groupInvoiceNumber = this.newContract?.groupInvoiceNumber;

    var endorsements = [];
    postData.forEach((i) => {
      i.endorsementDescription = `Service addition with tag ${i.uniqueTag}`;
      i.endorsementTypeId = endorsementTypeId;
      i.contractId = contractId;
      i.customerDivisionId = this.customerDivisionInformation.id;
      i.documentUrl = this.documentUrls.join(",");
      i.groupContractCategory = groupContractCategory;
      i.groupInvoiceNumber = groupInvoiceNumber;
      i.description = this.contractName;
      if (this.additionContractChoice && this.additionContractChoice == 1) {
        if (
          this.filteredContractServices == null ||
          this.filteredContractServices.length <= 0
        ) {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: `You have no active contract services, you cant perform a same contract addition endorsement`,
          });
          this.validatingAddition = false;
          this.ResetMessageToasters();
          return;
        }
        if (this.applyToOnlyCurrentMonth == 1) {
          let cntStartDate = new Date(i.contractStartDate);
          var newDate = new Date(
            cntStartDate.setMonth(cntStartDate.getMonth() + 1)
          );
          newDate.setDate(newDate.getDate() - 1);
          i.contractEndDate = newDate;
        } else {
          let oldestContractService: ContractService = null;
          this.filteredContractServices.forEach((x) => {
            if (oldestContractService == null) {
              oldestContractService = x;
            } else {
              if (x.contractEndDate > oldestContractService.contractEndDate) {
                oldestContractService = x;
              }
            }
          });

          i.contractEndDate = oldestContractService.contractEndDate;
          // i.contractEndDate =
          //   this.filteredContractServices[
          //     this.filteredContractServices.length - 1
          //   ].contractEndDate;
        }
      }
      endorsements.push(i);
    });

    this.messageService.add({
      severity: "info",
      summary: "Wait",
      detail: "Updating Quote",
    });
    this.ResetMessageToasters();
    this.endorsementService.postEndorsement(endorsements).subscribe(
      async (res: CommonResponse) => {
        this.validatingAddition = false;
        if (res.responseCode == "00") {
          this.fetchNewAdditionContract();
          this.activeTabIndex = 1;
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail: "Endorsement successfully processed.",
          });
          this.ResetMessageToasters();
          this.uploadedDocuments = [];
          this.additionIsSubmitted = false;
          this.selectedQuoteService = [];
          if (this.fileUploader) {
            this.fileUploader.clear();
          }
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: `${res.responseMsg}`,
          });
          this.ResetMessageToasters();
        }
      },
      (error) => {
        this.validatingAddition = false;

        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `${error.error.message}`,
        });
        this.ResetMessageToasters();
      }
    );

    // await this.refreshQuoteServices();
  }

  private matchAdminWithDirectDates(): boolean {
    var allDirects = this.selectedQuoteService?.filter(
      (p) => p.serviceRelationshipEnum == ServiceRelationshipEnum.Direct
    );

    let shouldReturn: boolean = false;
    for (var thisDirect of allDirects) {
      var [hasAdmin, theAdmin] = this.thisDirectHasAnAdmin(thisDirect);
      if (!hasAdmin) {
        shouldReturn = true;
        break;
      } else {
        //update this
        this.updatAdminDates(thisDirect, theAdmin);
      }
      if (shouldReturn) return;
    }
  }

  private updatAdminDates(direct: any, admin: any) {
    //check if this has an admin
    if (admin) {
      //change corresponding values of the admin
      admin.quantity = direct.quantity;
      admin.branchId = direct.branchId;
      admin.officeId = direct.officeId;
      admin.contractEndDate = direct.contractEndDate;
      admin.contractStartDate = direct.contractStartDate;
      admin.firstInvoiceSendDate = direct.firstInvoiceSendDate;
      admin.fulfillmentEndDate = direct.fulfillmentEndDate;
      admin.fulfillmentStartDate = direct.fulfillmentStartDate;
      admin.activationDate = direct.activationDate;
      admin.paymentCycle = direct.paymentCycle;
      admin.invoicingInterval = direct.invoicingInterval;

      //find the index of the admin in the selectedQuoteService
      let index = this.selectedQuoteService.findIndex(
        (x) =>
          x.adminDirectTie == admin.adminDirectTie &&
          x.serviceRelationshipEnum == ServiceRelationshipEnum.Admin
      );
      if (index > -1) {
        //calculate the billable vat on this admin again
        this.selectedQuoteService[index] = admin;
      }
    }
  }

  fetchNewAdditionContractV2() {
    this.fetchingSBUInfo = true;
    var id = this.selectedCustomerDivision.id;
    this.endorsementService.GetNewContractAdditionEndorsementV2(id).subscribe(
      async (res: CommonResponse) => {
        this.fetchingSBUInfo = false;
        if (res.responseCode == "00") {
          var contactServices: ContractService[] = res.responseData;

          contactServices.forEach((value) => {
            value.service = this.services.find((x) => x.id == value.serviceId);
            value.sbutoContractServiceProportions.forEach((sbuInfo) => {
              sbuInfo.userInvolved = this.allUsers.find(
                (x) => x.id == sbuInfo.userInvolvedId
              );
            });
            if (value.sbutoContractServiceProportions.length > 0) {
              this.canUploadGroupDocs = false;
            }
          });
          this.selectedGeneralService = contactServices;
          this.toggleSBUPanel(contactServices);
        }
      },
      (error) => {
        this.fetchingSBUInfo = false;
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `${error.error.message}`,
        });
        this.ResetMessageToasters();
      }
    );
  }

  fetchNewAdditionContract() {
    return this.fetchNewAdditionContractV2();

    this.fetchingSBUInfo = true;
    var id = this.selectedCustomerDivision.id;
    this.endorsementService.getNewContractAdditionEndorsement(id).subscribe(
      async (res: CommonResponse) => {
        this.fetchingSBUInfo = false;
        if (res.responseCode == "00") {
          var contactServices = res.responseData;

          contactServices.forEach((value) => {
            if (value.sbutoContractServiceProportions.length > 0) {
              this.canUploadGroupDocs = false;
            }
          });
          this.selectedGeneralService = contactServices;
          this.toggleSBUPanel(contactServices);
        }
      },
      (error) => {
        this.fetchingSBUInfo = false;
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `${error.error.message}`,
        });
        this.ResetMessageToasters();
      }
    );
  }

  private toggleSBUPanel(contractServiceGeneral: any[]) {
    if (contractServiceGeneral.length > 0) {
      this.hasPendingSBU = true;
      this.activeTabIndex = 1;
    } else {
      this.hasPendingSBU = false;
      this.activeTabIndex = 0;
    }
  }

  createAddtionServices() {
    if (!this.contractName) {
      this.messageService.add({
        severity: "error",
        summary: "Canceled",
        detail: "Please enter contract name",
      });
      this.ResetMessageToasters();
      return;
    }

    if (
      this.additionContractChoice &&
      this.additionContractChoice == 1 &&
      !this.applyToOnlyCurrentMonth
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Canceled",
        detail: "Please specify service behaviour",
      });
      this.ResetMessageToasters();
      return;
    }

    if (this.processAdditionChecks()) {
      this.processAllEndorsement(null, 4);
    }
  }

  private processAdditionChecks() {
    this.additionIsSubmitted = true;
    if (this.selectedQuoteService.length < 0) {
      this.messageService.add({
        severity: "error",
        summary: "Removed",
        detail: "Please add one or more service to the contract",
      });
      this.validatingAddition = false;
      this.ResetMessageToasters();
      return false;
    }

    //check that all directs of quotes have corresponding admin
    var [hasNoUnpaired, message, unpiaredQuote] = this.hasNoUnpairedDirects();
    if (!hasNoUnpaired) {
      this.messageService.add({
        severity: "error",
        summary: "Unpaired Directs",
        detail: message,
      });
      this.validatingAddition = false;
      this.ResetMessageToasters();
      return false;
    }

    if (this.uploadedDocuments.length == 0) {
      this.validatingAddition = false;
      return false;
    }

    return true;
  }

  uploadGroupDocuments() {
    //todo
    // this.quoteId = this.leadDivisions[0].quote?.id;
    this.qualifyService(this.selectedGeneralService[0], true);
  }

  qualifyService(generalService: GeneralService, isGroup: boolean = false) {
    let p = this.isGroupUpload;
    this.qualifyServiceRef = this.dialogService.open(SbuInfoComponent, {
      showHeader: false,
      closable: true,
      width: "50%",
      contentStyle: { "min-height": "550px", height: "100%", overflow: "auto" },
      baseZIndex: 980,
      data: {
        generalService: generalService,
        isGroupUpload: isGroup,
        quoteId: this.quoteId,
        allQuoateServices: this.selectedGeneralService,
      },
    });
    this.qualifyServiceRef.onClose.subscribe((res: any) => {
      this.fetchNewAdditionContract();
      if (res) {
        if (isGroup) {
          this.isGroupUpload = true;
        }
      }
    });
  }

  displayQuote(quoteServices: any) {
    if (this.isInvoiceGrouped) {
      quoteServices = this.prepareQuoteData();
    }

    let generalServices: any = [];
    for (let index = 0; index < quoteServices.length; index++) {
      let _service = this.services.find(
        (x) => x.id == quoteServices[index].serviceId
      );
      let general = { service: _service, quoteService: quoteServices[index] };
      generalServices.push(general);
    }

    this.viewQuoteRef = this.dialogService.open(ViewQuoteComponent, {
      width: "900px",
      contentStyle: { "max-width": "100vw", height: "90vh", overflow: "auto" },
      baseZIndex: 100,
      data: {
        generalServices: generalServices,
        //   quote: this.selectedDivision.quote,
        //   leadDivision: this.selectedDivision
      },
    });

    this.viewQuoteRef.onClose.subscribe(() => {});
  }

  RunViewReset(code: number) {
    if (code == 1) {
      this.endorseContractClicked = false;
      this.endorseContractServiceClicked = true;
      this.openBulkCancellation = false;
      this.openBulkRenewal = false;
    } else if (code == 2) {
      this.endorseContractClicked = true;
      this.endorseContractServiceClicked = false;
      this.openBulkCancellation = false;
      this.openBulkRenewal = false;
      this.applyToOnlyCurrentMonth = null;
    } else if (code == 3) {
      this.endorseContractClicked = false;
      this.endorseContractServiceClicked = false;
      this.openBulkCancellation = true;
      this.openBulkRenewal = false;
    } else if (code == 4) {
      this.endorseContractClicked = false;
      this.endorseContractServiceClicked = false;
      this.openBulkCancellation = false;
      this.openBulkRenewal = true;
    } else {
      this.endorseContractClicked = false;
      this.endorseContractServiceClicked = false;
      this.openBulkCancellation = false;
      this.openBulkRenewal = false;
    }
  }

  OpenBulkCancellation() {
    this.preGroupedContractServices = [];
    let groupedContractServices = this.groupByArray(
      this.selectedContract.contractServices,
      "contractEndDate"
    );

    groupedContractServices.forEach((groupedContractService) => {
      if (this.preGroupedContractServices.length == 0)
        this.preGroupedContractServices = groupedContractService.values;
      else if (
        this.preGroupedContractServices[0].contractEndDate <
        groupedContractService.key
      )
        this.preGroupedContractServices = groupedContractService.values;
    });

    if (!this.RunBulkPreValidation()) return;

    if (this.preGroupedContractServices.length > 0) {
      this.endorsementService
        .getEndorsementPossibleStartDates(this.preGroupedContractServices[0].id)
        .subscribe(
          async (res: CommonResponse) => {
            this.allCancellatonDateOptions = res.responseData;
          },
          (error) => {
            this.connectionError();
          }
        );
      this.allContractServicesCancellable = this.preGroupedContractServices;
    }

    this.RunViewReset(3);
  }

  OpenBulkRenewal() {
    this.preGroupedContractServices = [];
    let groupedContractServices = this.groupByArray(
      this.selectedContract.contractServices,
      "contractEndDate"
    );

    groupedContractServices.forEach((groupedContractService) => {
      if (this.preGroupedContractServices.length == 0)
        this.preGroupedContractServices = groupedContractService.values;
      else if (
        this.preGroupedContractServices[0].contractEndDate <
        groupedContractService.key
      )
        this.preGroupedContractServices = groupedContractService.values;
    });

    if (!this.RunBulkPreValidation()) return;

    // this.allContractServicesRenewable = this.selectedContract.contractServices;
    this.allContractServicesRenewable = this.preGroupedContractServices;
    this.RunViewReset(4);
    //Capture event -- PAYE STATE REPROT
  }

  RunBulkPreValidation(): boolean {
    this.RunViewReset(0);
    let groupContractCateg = this.selectedContract.groupContractCategory;

    if (groupContractCateg == GroupContractCategory.IndividualContract) {
      console.log("Invalid: " + this.selectedContract.groupContractCategory);
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "You can't perform this operation on an individual contract, only group contracts are allowed.",
      });
      this.ResetMessageToasters();
      return false;
    }

    if (
      groupContractCateg ==
      GroupContractCategory.GroupContractWithIndividualDetails
    ) {
      console.log("Invalid: " + this.selectedContract.groupContractCategory);
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "You can't perform this operation on a grouped contract without same details, only grouped contracts with same details are allowed.",
      });
      this.ResetMessageToasters();
      return false;
    }

    let notSame: boolean = false;
    // this.selectedContract.contractServices.forEach((contractServA) => {
    //   this.selectedContract.contractServices.forEach((contractServB) => {
    //     if (contractServA.contractEndDate != contractServB.contractEndDate)
    //       notSame = true;
    //   });
    // });
    this.preGroupedContractServices.forEach((contractServA) => {
      this.preGroupedContractServices.forEach((contractServB) => {
        if (contractServA.contractEndDate != contractServB.contractEndDate)
          notSame = true;
      });
    });
    if (notSame) {
      console.log("Invalid: " + this.selectedContract.id);
      this.messageService.add({
        severity: "error",
        summary: "Notice",
        detail:
          "You can't perform this operation on this grouped contract because not all the contract services have the same duration.",
      });
      this.ResetMessageToasters();
      return false;
    }

    this.allContractServicesCancellable = [];
    this.selectedContractServicesCancellable = [];
    this.allContractServicesRenewable = [];
    this.selectedContractServicesRenewable = [];
    return true;
  }

  OnCancelDocUpload() {
    this.uploadedDocs = [];
  }

  OnCancelCancDocUpload() {
    this.uploadedCancellationDocs = [];
  }

  NotifyUserToClickUpload() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });
    this.ResetMessageToasters();
  }

  NotifyUserToClickUploadCancellationDoc() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Kindly ensure you click upload.",
    });
    this.ResetMessageToasters();
  }

  OnUploadDoc(event) {
    this.uploadedDocs = [];
    for (const file of event.files) {
      this.uploadedDocs.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Doc(s) Uploaded",
    });
    this.ResetMessageToasters();
  }

  OnUploadCancellationDoc(event) {
    this.uploadedCancellationDocs = [];
    for (const file of event.files) {
      this.uploadedCancellationDocs.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Doc(s) Uploaded",
    });
    this.ResetMessageToasters();
  }

  async ProcessRenewal() {
    if (!this.hasSuccessfulValidation) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "You need a successful contract validation to proceed, Kindly make use of the 'Check Contract Validity' button.",
      });
      this.ResetMessageToasters();
      return;
    }

    if (this.theInvoiceCycle.id == 6) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You can't perform an adhoc bulk renewal.",
      });
      this.ResetMessageToasters();
      return;
    }

    if(this.allContractServicesRenewable.some((x) => x.invoicingInterval == 6)){
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "You can't perform any renewal on an adhoc contract. Kindly create a new contract.",
      });
      this.ResetMessageToasters();
      return;
    }

    let contractServEndDate =
      this.selectedContractServicesRenewable[0].contractEndDate.substring(
        0,
        10
      );

    if (new Date(contractServEndDate) >= new Date(this.renewalStartDate)) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "Renewal Start Date must be greater than current contract end date",
      });
      this.ResetMessageToasters();
      return;
    }

    if (this.renewalStartDate >= this.renewalEndDate) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "Renewal End Date must be greater than renewal start date",
      });
      this.ResetMessageToasters();
      return;
    }

    this.confirmationService.confirm({
      message: "Are you sure you want to process this endorsements ?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Renewing Selected Services......",
        });
        this.ResetMessageToasters();

        if (this.uploadedDocs.length > 0) {
          this.messageService.add({
            severity: "info",
            summary: "Notice",
            detail: "Uploading documents.",
          });
          this.ResetMessageToasters();

          let uploadUrls: string[] = [];
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.uploadedDocs
          );
          this.fileStorageService.onUploadFinished.subscribe(
            (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
                this.ResetMessageToasters();
              } else {
                uploadUrls = resp.responseData;
                this._processRenewal(uploadUrls);
              }
            },
            (error) => {
              console.log("Error while uploading files " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image to storage",
              });
              this.ResetMessageToasters();
            }
          );
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "You must upload endorsement instructions (docs).",
          });
          this.ResetMessageToasters();
        }
      },
    });
  }

  async _processRenewal(uploads: string[]) {
    let postData = [];
    let count: number = 1;
    this.selectedContractServicesRenewable
      .filter((x) => x.service.serviceRelationshipEnum != 3)
      .forEach((contractServ) => {
        const unitPrice = contractServ.unitPrice;
        const quantity = contractServ.quantity;
        const endorsementDescription = this.renewalDescription;
        const contractService = contractServ;
        var endorsement = "retention";
        contractService.unitPrice = unitPrice;
        contractService.quantity = quantity;

        //Calcualate Billable Amt, Vat, Discount
        var service = contractServ?.service;
        let _unitPrice = unitPrice ?? service.unitPrice;
        const _quantity = quantity ?? 1;
        let _discount = contractServ.discount ?? 0;
        if (_discount > 100) {
          _discount = 100;
        }
        const totalPrice = unitPrice * quantity;
        let vat: number;
        if (service.isVatable) {
          vat = parseFloat((totalPrice * 0.075).toFixed(2));
        } else {
          vat = 0;
        }
        const billable = totalPrice - (_discount / 100) * totalPrice + vat;
        contractService.billableAmount = billable;
        contractService.vat = vat;
        contractService.budget = billable;

        if (billable > contractServ.billableAmount) {
          contractService.endorsementDescription = "Service TopUp";
        } else if (billable < contractServ.billableAmount) {
          contractService.endorsementDescription = "Service Reduction";
        } else {
          contractService.endorsementDescription = "NO_CHANGE";
        }

        contractService.invoicingInterval = this.theInvoiceCycle.id;
        contractService.paymentCycle = this.thePaymentCycle.id;
        // contractService.uniqueTag = this.renewalUniqueTag;
        contractService.endorsementDescription = endorsementDescription;
        const endorsementTypeId = this.endorsementTypes.find((x) =>
          x.caption.toLowerCase().includes(endorsement.toLowerCase())
        ).id;
        contractService.endorsementTypeId = endorsementTypeId;
        contractService.isRequestedForApproval = true;
        contractService.isApproved = false;
        contractService.isDeclined = false;
        contractService.previousContractServiceId = contractService.id;
        contractService.dateForNewContractToTakeEffect = this.renewalStartDate;
        contractService.contractStartDate = this.renewalStartDate + "";
        contractService.contractEndDate = this.renewalEndDate + "";

        // Move branch and office from lead division to customer division
        contractService.contractId = this.selectedContract.id;
        contractService.branchId = contractServ?.branchId;
        contractService.officeId = contractServ?.officeId;
        contractService.customerDivisionId =
          this.customerDivisionInformation.id;
        contractService.documentUrl = uploads.join(",");

        //check if this is direct and effect quanity change on corresponding admin
        let adminPair: ContractService = null;
        if (
          contractService?.service?.serviceRelationshipEnum ==
          ServiceRelationshipEnum.Direct
        ) {
          //look for the matching admin
          adminPair = this.allContractServicesRenewable.find(
            (x) =>
              x.service?.serviceRelationshipEnum ==
                ServiceRelationshipEnum.Admin &&
              x.adminDirectTie == contractServ.adminDirectTie
          );

          if (adminPair) {
            this.adminPair = adminPair;
            let modifiedAdmin = this.prepareAdminContractServiceForPosting(
              contractService,
              adminPair
            );
            modifiedAdmin.adminDirectTie = new Date().valueOf() + count;
            console.log("admin", modifiedAdmin.adminDirectTie);

            postData.push(modifiedAdmin);
            count++;
          }
        }
        if (adminPair) {
          contractService.adminDirectTie = adminPair.adminDirectTie;
          console.log("direct", contractService.adminDirectTie);
        }
        postData.push(contractService);
      });

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Setting up bulk endorsement for approvals.",
    });
    this.ResetMessageToasters();

    this.endorsementService.postEndorsement(postData).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail:
              "Endorsement processed and sent for approvals successfully.",
          });
          this.ResetMessageToasters();
          this.uploadedDocs = [];
          this.selectedContractServicesRenewable = [];
          this.renewalDescription = null;
          this.theInvoiceCycle = null;
          this.thePaymentCycle = null;
          this.renewalUniqueTag = null;
          this.renewalStartDate = null;
          this.renewalEndDate = null;
          this.hasSuccessfulValidation = false;
          this.RunViewReset(0);
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: `${res.responseMsg}`,
          });
          this.ResetMessageToasters();
          this.allContractServicesRenewable = [];
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `${error.error.message}`,
        });
        this.ResetMessageToasters();
        // this.allContractServicesRenewable =  this.selectedContract.contractServices;
      }
    );
  }

  async ProcessCancellation() {
    if (!this.hasSuccessfulValidation) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "You need a successful contract validation to proceed, Kindly make use of the 'Check Contract Validity' button.",
      });
      this.ResetMessageToasters();
      return;
    }

    this.confirmationService.confirm({
      message: "Are you sure you want to process this endorsements ?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Cancelling Selected Services......",
        });
        this.ResetMessageToasters();

        if (this.uploadedCancellationDocs.length > 0) {
          this.messageService.add({
            severity: "info",
            summary: "Notice",
            detail: "Uploading documents.",
          });
          this.ResetMessageToasters();
          let uploadUrls: string[] = [];
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.uploadedCancellationDocs
          );
          this.fileStorageService.onUploadFinished.subscribe(
            (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
                this.ResetMessageToasters();
              } else {
                uploadUrls = resp.responseData;
                this._processCancellation(uploadUrls);
              }
            },
            (error) => {
              console.log("Error while uploading files " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image to storage",
              });
              this.ResetMessageToasters();
            }
          );
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail: "You must upload endorsement instructions (docs).",
          });
          this.ResetMessageToasters();
        }
      },
    });
  }

  async _processCancellation(uploads: string[]) {
    let postData = [];
    this.selectedContractServicesCancellable
      .filter((x) => x.service.serviceRelationshipEnum != 3)
      .forEach((contractServ) => {
        const unitPrice = contractServ.unitPrice;
        const quantity = 0;
        const endorsementDescription = this.cancellationDescription;
        const contractService = contractServ;
        var endorsement = "Service Reduction";
        contractService.unitPrice = unitPrice;
        contractService.quantity = quantity;
        contractService.vat = 0;
        contractService.billableAmount = 0;
        contractService.budget = 0;

        contractService.endorsementDescription = endorsementDescription;
        const endorsementTypeId = this.endorsementTypes.find((x) =>
          x.caption.toLowerCase().includes(endorsement.toLowerCase())
        ).id;

        contractService.endorsementTypeId = endorsementTypeId;
        contractService.isRequestedForApproval = true;
        contractService.isApproved = false;
        contractService.isDeclined = false;
        contractService.previousContractServiceId = contractService.id;
        contractService.dateForNewContractToTakeEffect =
          this.theCancellationDateOption.startDate;
        contractService.contractStartDate =
          this.theCancellationDateOption.startDate + "";
        contractService.contractEndDate = "";

        // Move branch and office from lead division to customer division
        contractService.contractId = this.selectedContract.id;
        contractService.branchId = contractServ?.branchId;
        contractService.officeId = contractServ?.officeId;
        contractService.customerDivisionId =
          this.customerDivisionInformation.id;
        contractService.documentUrl = uploads.join(",");

        let adminPair: ContractService = null;
        if (
          contractServ.service.serviceRelationshipEnum ==
          ServiceRelationshipEnum.Direct
        ) {
          //look for the matching admin
          adminPair = this.allContractServicesCancellable.find(
            (x) =>
              x.service?.serviceRelationshipEnum ==
                ServiceRelationshipEnum.Admin &&
              x.adminDirectTie == contractServ.adminDirectTie
          );

          if (adminPair) {
            this.adminPair = adminPair;
            let modifiedAdmin = this.prepareAdminContractServiceForPosting(
              contractService,
              adminPair
            );
            postData.push(modifiedAdmin);
          }
        }
        postData.push(contractService);
      });

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Setting up bulk endorsement for approvals.",
    });
    this.ResetMessageToasters();

    this.endorsementService.postEndorsement(postData).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail:
              "Endorsement processed and sent for approvals successfully.",
          });
          this.ResetMessageToasters();
          this.selectedContractServicesCancellable = [];
          this.cancellationDescription = null;
          this.theCancellationDateOption = null;
          this.uploadedCancellationDocs = [];
          this.hasSuccessfulValidation = false;
          this.RunViewReset(0);
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failed",
            detail: `${res.responseMsg}`,
          });
          this.ResetMessageToasters();
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `${error.error.message}`,
        });
        this.ResetMessageToasters();
      }
    );
  }

  groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  groupByArray(xs, key): [{ key: string; values: any[] }] {
    return xs.reduce(function (rv, x) {
      let v = key instanceof Function ? key(x) : x[key];
      let el = rv.find((r) => r && r.key === v);
      if (el) {
        el.values.push(x);
      } else {
        rv.push({
          key: v,
          values: [x],
        });
      }
      return rv;
    }, []);
  }

  CheckContractValidity() {
    this.confirmationService.confirm({
      message:
        "This action will check the selected contract (Contract-" +
        this.selectedContract.id +
        ") validity. Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Checking contract validity......",
        });
        this.ResetMessageToasters();

        this.contractService
          .CheckContractValidity(this.selectedContract.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                this.hasSuccessfulValidation = false;
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail: "Contract validated successfully. [0 Issue(s) found]",
              });
              this.ResetMessageToasters();
              this.hasSuccessfulValidation = true;
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to validate selected contract at the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
              this.ResetMessageToasters();
              this.hasSuccessfulValidation = false;
            }
          );
      },
    });
  }

  ReValidateContract() {
    this.confirmationService.confirm({
      message:
        "This action will re-validate the selected contract (Contract-" +
        this.selectedContract.id +
        ") and make necessary correction(s). Do you still wish to proceed?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Revalidating contract......",
        });
        this.ResetMessageToasters();

        this.contractService
          .ReValidateContract(this.selectedContract.id)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                if (
                  data.responseMsg ==
                  "You can't revalidate a contract without any issues."
                ) {
                  this.messageService.add({
                    severity: "warn",
                    summary: "Info",
                    detail:
                      data.responseMsg +
                      " Please proceed with your endorsement action(s).",
                  });
                  this.ResetMessageToasters();
                  return;
                }

                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: data.responseMsg,
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail:
                  "Contract re-validated successfully. [All Issue(s) resolved.]",
              });
              this.ResetMessageToasters();
            },
            (error) => {
              console.log("Error: " + JSON.stringify(error));
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail:
                  "Unable to re-validate selected contract the moment.. Reason: [" +
                  error.error.message +
                  "]",
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }

  FormatDateString(date: Date): string {
    let dateString = date.toString();

    try {
      // DateTimeFormatOptions
      const date: Date = new Date(dateString);
      const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      const formattedDate: string = new Intl.DateTimeFormat(
        "en-US",
        options
      ).format(date);
      dateString = formattedDate;
    } catch (error) {
      console.log(error);
      console.log(date);
      console.log(
        "Error while converting date string " + date + " exception " + error
      );
    }

    return dateString;
  }

  OpenBulkDateEndorse() {
    if (!this.hasSuccessfulValidation) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "You need a successful contract validation to proceed, Kindly make use of the 'Check Contract Validity' button.",
      });
      this.ResetMessageToasters();
      return;
    }

    if (
      this.selectedContract.groupContractCategory !=
      GroupContractCategory.GroupContractWithSameDetails
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "You can only perform date endorsement on group contract category with same details.",
      });
      this.ResetMessageToasters();
      return;
    }

    if (this.includeExpiredContractService) {
      this.includeExpiredContractService = false;
      this.toggleExpiredContractService();
    }

    // Validation if there is no active contract services
    if (this.filteredContractServices.length == 0) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "There are no active contract services to perform this endorsement on.",
      });
      this.ResetMessageToasters();
      return;
    }

    this.allContractServicesAffected = this.filteredContractServices;
    let contractServ = this.allContractServicesAffected[0];
    let month = +contractServ.contractStartDate.slice(5, 7);
    let day = +contractServ.contractStartDate.slice(8, 10);
    this.theActivationMonth = this.activationMonthOptions[month - 1];
    this.theNewActivationDay = this.activationDayOptions[day - 1];
    this.newActivationYear = +contractServ.contractStartDate.slice(0, 4);
    this.openDateEndorse = true;
  }

  HideDateEndorseDialog() {
    this.openDateEndorse = false;
    this.allContractServicesAffected = [];
    this.selectedContractServicesAffected = [];
    this.theNewActivationDay = null;
    this.theActivationMonth = null;
    this.newActivationYear = null;
    this.isDifferentMonthChange = false;
  }

  SendDateEndorsement() {
    this.confirmationService.confirm({
      message: "Are you sure you want to process this date endorsement(s)?",
      accept: async () => {
        this.messageService.add({
          severity: "info",
          summary: "Notice",
          detail: "Processing Date Endorsement......",
        });
        this.ResetMessageToasters();

        this.selectedContractServicesAffected
          .filter((x) => x.service.serviceRelationshipEnum == 2)
          .forEach((directContractServ) => {
            let adminContractServ = this.allContractServicesAffected.find(
              (x) =>
                x.adminDirectTie == directContractServ.adminDirectTie &&
                x.id != directContractServ.id
            );
            if (
              adminContractServ &&
              this.selectedContractServicesAffected.find(
                (x) => x.id == adminContractServ.id
              ) == null
            ) {
              this.selectedContractServicesAffected.push(adminContractServ);
            }
          });

        const postData: PostDateEndrosementForApprovalVM = {
          isDifferentMonthChange: this.isDifferentMonthChange,
          contractServiceIds: this.selectedContractServicesAffected.map(
            (x) => x.id
          ),
          newActivationDay: this.theNewActivationDay.Key,
          newActivationMonth: this.theActivationMonth.Key,
          newActivationYear: this.newActivationYear,
        };

        this.endorsementService
          .PostDateEndrosementForApproval(postData)
          .subscribe(
            async (data) => {
              if (data.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: `${data.responseMsg}`,
                });
                this.ResetMessageToasters();
                return;
              }

              this.messageService.add({
                severity: "success",
                summary: "Success",
                detail:
                  "Endorsement processed and sent for approvals successfully.",
              });
              this.ResetMessageToasters();
              this.HideDateEndorseDialog();
            },
            (error) => {
              this.messageService.add({
                severity: "error",
                summary: "Failed",
                detail: `${error.error.message}`,
              });
              this.ResetMessageToasters();
            }
          );
      },
    });
  }
}
