import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService, TreeNode } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AttachUserToOfficeReceivingDTO,
  Branch,
  Office,
  User,
} from "src/app/interfaces/home";
import { BranchService } from "src/app/services/branch.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-attach-user-branch-office",
  templateUrl: "./attach-user-branch-office.component.html",
  styleUrls: ["./attach-user-branch-office.component.scss"],
})
export class AttachUserBranchOfficeComponent implements OnInit {
  primaryAttachmentForm: FormGroup;
  branchAndOfficeTree: TreeNode[];
  selectedBranchOffice: Office;
  loadingBranchAndOffice: boolean;
  openPrimaryAttachmentDialogue: boolean;
  fetchingPrimaryAttachments: boolean;
  allPrimaryAttachments: User[] = [];
  selectedPrimaryAttachments: User[] = [];
  primaryAttachmentCols: any[];
  allUsers: User[] = [];
  thePrimaryUser: User;
  allBranches: Branch[] = [];

  constructor(
    private branchService: BranchService,
    public formBuilder: FormBuilder,
    private userService: UserService,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.primaryAttachmentForm = formBuilder.group({
      User: ["", Validators.required],
      Office: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Branch & Office", routerLink: ["home/account/branch-office"] },
      {
        label: "Attach User",
        routerLink: ["home/account/attach-user-branch-office"],
      },
    ]);

    this.primaryAttachmentCols = [
      { field: "firstName", header: "First Name" },
      { field: "lastName", header: "Last Name" },
      { field: "codeName", header: "Code Name" },
    ];

    this.FetchAllBranchAndOffices();
    this.FetchAllUsers();
  }

  async FetchAllBranchAndOffices() {
    this.loadingBranchAndOffice = true;

    this.branchService.GetAllBranchesWithOffices().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.loadingBranchAndOffice = false;
          return;
        }
        this.allBranches = data.responseData;
        this.LoadUpBranchOfficeTree();
        this.loadingBranchAndOffice = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all branches at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.loadingBranchAndOffice = false;
      }
    );
  }

  async LoadUpBranchOfficeTree() {
    this.branchAndOfficeTree = [];

    this.allBranches.forEach((branch) => {
      let branchTree: TreeNode = {
        key: branch.id + "-Branch",
        label: branch.name,
        expandedIcon: "pi pi-minus-circle",
        collapsedIcon: "pi pi-plus-circle",
        expanded: true,
        children: [],
      };
      branch.offices.forEach((office) =>
        branchTree.children.push({
          label: office.name,
          key: office.id + "-Office",
          icon: "pi pi-fw pi-briefcase",
          data: office,
          selectable: true,
        })
      );
      this.branchAndOfficeTree.push(branchTree);
    });
  }

  async FetchAllUsers() {
    this.userService.allUser().subscribe(async (res) => {
      this.allUsers = res.responseData;
      this.allUsers.forEach(
        (x) =>
          (x.fullName =
            x.lastName + " " + x.firstName + " " + (x.otherName ?? ""))
      );
    });
  }

  async LoadSelectedBranchMembers(){
    if(this.selectedBranchOffice){
      this.userService.FindAllUsersInAnBranch(this.selectedBranchOffice.id).subscribe(async(data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.allPrimaryAttachments = data.responseData;
      }, (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all members of selected branch at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      })
    }
  }

  DisplayUsers(event) {
    this.allPrimaryAttachments = [];
    if (event.node.data) {
      if (this.selectedBranchOffice !== event.node.data) {
        this.selectedBranchOffice = event.node.data;
        this.LoadSelectedBranchMembers();
      }
    } else {
      this.selectedBranchOffice = null;
    }
  }

  ShowPrimaryAddUserPopUp() {
    if (this.selectedBranchOffice) this.openPrimaryAttachmentDialogue = true;
    else
      this.messageService.add({
        severity: "error",
        summary: "Select A Branch/Office ",
      });
  }

  CreatePrimaryAttachment() {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Attaching user to Office...",
    });

    const postData: AttachUserToOfficeReceivingDTO = {
      branchId: this.selectedBranchOffice.branchId,
      officeId: this.selectedBranchOffice.id,
      userId: this.thePrimaryUser.id,
    };

    this.userService.AttachUserToOffice(postData).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "User Attached Successfully!",
        });
        this.allPrimaryAttachments.push(
          this.allUsers.find((x) => x.id == postData.userId)
        );
        this.HidePrimaryAttachmentDialog();
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to attach user at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  PrimaryDetach(user: User) {}

  HidePrimaryAttachmentDialog() {
    this.openPrimaryAttachmentDialogue = false;
  }
}
