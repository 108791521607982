import { Component, Input, OnInit } from "@angular/core";
import { AngularFireStorage } from "@angular/fire/storage";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfirmationService, MessageService, SelectItem } from "primeng/api";
import { FileUpload } from "primeng/fileupload";
import { forkJoin } from "rxjs";
import {
  CommonResponse,
  ContractService,
  EndorsementType,
  ServicePricing,
  ServiceRelationshipEnum,
} from "src/app/interfaces/home";
import { EndorsementService } from "src/app/services/endorsement.service";
import { FileStorageService } from "src/app/services/file-storage.service";
import { ServicePricingService } from "src/app/services/service-pricing.service";

@Component({
  selector: "app-credit-debit-note",
  templateUrl: "./credit-debit-note.component.html",
  styleUrls: ["./credit-debit-note.component.scss"],
})
export class CreditDebitNoteComponent implements OnInit {
  private _contractServiceUnderEndorsement: any;
  private invoicingInterval: number;
  daysAffectedError: string = "";
  daysRatioTotal: number;
  @Input() customerDivisionInformation: any;
  @Input() selectedContract: any;
  @Input() canModifyQuantity: boolean;
  //quantityTracker: number = 0;
  @Input() serviceQuantity: any;
  @Input() shouldPairAdmin: boolean = false;
  @Input() adminPair: any;
  @Input() hasSuccessfulValidation: boolean;
  daysInMonth: string;
  daysAffected: string;
  @Input() set contractServiceUnderEndorsement(value: any) {
    if (value) {
      this._contractServiceUnderEndorsement = value;
      this.invoicingInterval = value.invoicingInterval;
      this.setupComponent();
    }
  }

  isSubmitted: boolean = false;
  get contractServiceUnderEndorsement(): any {
    return this._contractServiceUnderEndorsement;
  }

  @Input() endorsementTypes: EndorsementType[];

  endorsementTypes2: SelectItem[] = [];
  creditNoteTypes: SelectItem[] = [];
  debitNoteTypes: SelectItem[] = [];

  selectedNoteType: SelectItem;

  form: FormGroup;

  creditDebitOrStandAlone: any;
  isStandAlone: boolean;
  unitPrice: number;
  quantity: number;
  endorsementEffect: any;
  vat: number;
  //billableAmount: number;
  discount: number;
  fileUploader: FileUpload;

  uploadedDocuments: any[] = [];
  documentUrls: string[] = [];
  directAffectAdminNotice: string;
  constructor(
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    private servicePricingService: ServicePricingService,
    private endorsementService: EndorsementService,
    public confirmationService: ConfirmationService,
    private angularFireStorage: AngularFireStorage,
    private fileStorageService: FileStorageService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      endorsementType: [null],
      noteType: [null, [Validators.required]],
      unitPrice: [""],
      quantity: [""],
      description: ["", Validators.required],
      daysAffected: [""],
      daysinmonths: [""],
      standAlone: ["", [Validators.required]],
      amount: ["", Validators.required],
      amountWithoutVat: [""],
      vat: [""],
    });

    this.endorsementTypes2 = [
      { label: "Credit Note", value: "Credit Note" },
      // { label: "Debit Note", value: "Debit Note" },
    ];

    this.creditNoteTypes = [
      { label: "Absence", value: "Absence" },
      { label: "Abscond", value: "Abscond" },
      { label: "Faulty", value: "Faulty" },
    ];

    this.debitNoteTypes = [{ label: "Additional", value: "Additional" }];

    this.setupComponent();
  }

  setupComponent() {
    this.unitPrice = this.contractServiceUnderEndorsement.unitPrice;
    this.quantity = 0;
    this.discount = this.contractServiceUnderEndorsement.discount;
    this.directAffectAdminNotice = `Note: Changes in the quantity of this service would affect the admin service with tag: '${this.adminPair?.uniqueTag}'`;

    //this.setUpServicePricings();
  }

  // setUpServicePricings(){
  //   const serviceId = this.contractServiceUnderEndorsement.serviceId
  //   this.servicePricingService.getServicePricingsByServiceId(serviceId).subscribe(async (res: ServicePricing[]) => {
  //     const servicePricings = res;

  //     let boundedQuantity = false;
  //     let min: number;
  //     let max: number;

  //     const branchId = this.contractServiceUnderEndorsement.branchId;
  //     if(branchId && servicePricings.length > 0){
  //       const servicePricing = servicePricings.find(x => x.branchId == branchId)
  //       if(servicePricing){
  //         boundedQuantity = true;
  //         min = servicePricing.minimumAmount;
  //         max = servicePricing.maximumAmount;
  //       }
  //     }

  //     if(boundedQuantity){
  //       this.form.get('unitPrice').setValidators([Validators.required, Validators.min(min), Validators.max(max)])
  //     }
  //   })
  // }

  getStandAlone(selection) {
    console.log(selection);

    this.creditDebitOrStandAlone = selection;
    this.isSubmitted = false;
    if (this.creditDebitOrStandAlone == 1) {
      this.isStandAlone = false;
      this.form.controls["unitPrice"].setValidators([
        Validators.required,
        Validators.min(1),
      ]);
      this.form.controls["daysAffected"].setValidators(Validators.required);
      this.form.controls["quantity"].setValidators(Validators.required);
      this.form.controls["endorsementType"].setValidators(Validators.required);
      this.form.controls["daysinmonths"].setValidators(Validators.required);
      this.form.controls["amountWithoutVat"].setValidators(Validators.required);
      this.form.controls["vat"].setValidators(Validators.required);
    } else {
      this.isStandAlone = true;
      this.form.controls["unitPrice"].setValidators(null);
      this.form.controls["daysAffected"].setValidators(null);
      this.form.controls["quantity"].setValidators(null);
      this.form.controls["endorsementType"].setValidators(null);
      this.form.controls["daysInMonths"].setValidators(null);
      this.form.controls["vat"].setValidators(Validators.required);
    }
  }

  processCreditDebitNoteEndorsement() {
    if (!this.hasSuccessfulValidation) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail:
          "You need a successful contract validation to proceed, Kindly make use of the 'Check Contract Validity' button.",
      });
      return;
    }

    if (!this.vat && this.isStandAlone) {
      this.messageService.add({
        severity: "error",
        summary: "Failed",
        detail: "VAT is required",
      });
      return;
    }

    this.isSubmitted = true;
    if (
      this.contractServiceUnderEndorsement?.service?.serviceRelationshipEnum ==
      ServiceRelationshipEnum.Admin
    ) {
      this.messageService.add({
        severity: "error",
        summary: "Cancelled",
        detail: "Cannot do credit/debit on an admin contract service",
      });
      return;
    }

    // if (this.standAlone == 2) {
    //   return;
    // } else
    if (this.form.invalid || this.endorsementEffect === "NO_CHANGE") {
      if (this.form.invalid) {
        // this.messageService.add({
        //   severity: "error",
        //   summary: "Cancelled",
        //   detail: "Please enter required inputs",
        // });

        var p = this.form.controls["daysAffected"].value;
        var m = this.form.controls["daysinmonths"].value;
        if (!this.isStandAlone && (m == "" || !m) && this.isSubmitted) {
          this.daysInMonth = "Days in month(s) is required";
        } else {
          this.daysInMonth = "";
        }

        if (!this.isStandAlone && (p == "" || !p) && this.isSubmitted) {
          this.daysAffected = "Days affected is required";
        } else {
          this.daysAffected = "";
        }
        console.log(this.form.invalid);
      } else
        this.messageService.add({
          severity: "error",
          summary: "Cancelled",
          detail: "You have not made any changes",
        });

      return;
    }

    if (this.uploadedDocuments.length == 0) {
      this.messageService.add({
        severity: "error",
        summary: "Cancelled",
        detail: "Please upload document",
      });
      return;
    }

    this.confirmationService.confirm({
      message: "Are you sure you want to process this endorsement ?",
      accept: () => {
        this.documentUrls = [];

        if (this.uploadedDocuments.length > 0) {
          this.fileStorageService.UploadMultipleFilesFromDataUrl(
            this.uploadedDocuments
          );

          this.fileStorageService.onUploadFinished.subscribe(
            (resp: CommonResponse<string[]>) => {
              if (resp.responseCode != "00") {
                this.messageService.add({
                  severity: "error",
                  summary: "Notice",
                  detail: resp.responseMsg,
                });
              } else {
                this.documentUrls = resp.responseData;
                this._processCreditDebitNoteEndorsement();
              }
            },
            (error) => {
              console.log("Error while creating platform " + error);
              this.messageService.add({
                severity: "error",
                summary: "Notice",
                detail: "ERR: Unable to upload image to storage",
              });
            }
          );
        }
        // if (this.uploadedDocuments && this.uploadedDocuments.length > 0) {
        //   const n = Date.now();
        //   const fileUploads = this.uploadedDocuments.map((file) => {
        //     const filePath = `EndorsementInstruction/${n}_${file.name}`;
        //     const ref = this.angularFireStorage.ref(filePath);
        //     const task = this.angularFireStorage.upload(filePath, file);
        //     return { ref, snapshot: task.snapshotChanges() };
        //   });

        //   const refs = fileUploads.map((x) => x.ref);
        //   const snapShots = fileUploads.map((x) => x.snapshot);
        //   this.messageService.add({
        //     severity: "info",
        //     summary: "Notice",
        //     detail: "Uploading documents.",
        //   });
        //   forkJoin(snapShots).subscribe(
        //     async (res) => {
        //       for (let i = 0; i < refs.length; i++) {
        //         const ref = refs[i];
        //         const downloadURL = await ref.getDownloadURL().toPromise();
        //         this.documentUrls.push(downloadURL);
        //       }
        //       this.messageService.add({
        //         severity: "success",
        //         summary: "Success",
        //         detail: "Document(s) uploaded successfully.",
        //       });
        //       this._processCreditDebitNoteEndorsement();
        //     },
        //     (error) => {
        //       this.connectionError();
        //     }
        //   );
        // } else {
        //   this._processCreditDebitNoteEndorsement();
        // }
      },
      reject: () => {
        this.messageService.add({
          severity: "error",
          summary: "Cancelled",
          detail: "Endorsement Cancelled",
        });
      },
    });
  }

  _processCreditDebitNoteEndorsement() {
    var postData = [];
    //daysAffected
    const quantity = this.form.get("quantity").value;
    if (this.creditDebitOrStandAlone == 1 && quantity <= 0) {
      this.messageService.add({
        severity: "error",
        summary: "error",
        detail: `Quantity entered must be greater than 0`,
      });
      return;
    }

    const endorsementDescription = this.form.get("description").value;

    if (quantity > this.serviceQuantity) {
      this.messageService.add({
        severity: "error",
        summary: "error",
        detail: `Quantity entered can not be greater than ${this.serviceQuantity}`,
      });
      return;
    }

    const contractService = this.contractServiceUnderEndorsement;

    const endorsementTypeId = this.endorsementTypes.find((x) =>
      x.caption
        .toLowerCase()
        .includes(this.form.value.endorsementType.toLowerCase())
    ).id;

    if (this.isStandAlone) {
      contractService.endorsementDescription = endorsementDescription + "--std";
    } else {
      contractService.endorsementDescription = endorsementDescription;
    }

    contractService.unitPrice = this.form.get("unitPrice").value;
    contractService.quantity = quantity;
    contractService.daysAffected = this.form.value?.daysAffected;
    contractService.vat = this.vat;
    contractService.billableAmount = this.form.get("amount").value; // this.billableAmount;
    contractService.budget = this.form.get("amount").value;
    //this.form.patchValue({amount: ""});

    contractService.endorsementTypeId = endorsementTypeId;
    contractService.isRequestedForApproval = true;
    contractService.isApproved = false;
    contractService.isDeclined = false;
    contractService.previousContractServiceId = contractService.id;
    contractService.dateForNewContractToTakeEffect = new Date(Date.now());

    // Move branch and office from lead division to customer division
    contractService.contractId = this.selectedContract.id;
    contractService.branchId = this.contractServiceUnderEndorsement?.branchId;
    contractService.officeId = this.contractServiceUnderEndorsement?.officeId;
    contractService.customerDivisionId = this.customerDivisionInformation.id;
    contractService.documentUrl = this.documentUrls.join(",");

    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Setting up endorsement for approvals.",
    });

    console.log("The Credit/Debit Note Endorsement Post Data", contractService);

    //check if this is direct and effect quanity change on corresponding admin
    if (
      contractService?.service?.serviceRelationshipEnum ==
        ServiceRelationshipEnum.Direct &&
      this.isStandAlone == false
    ) {
      //check if the quanity changed
      if (this.quantity > 0 && this.shouldPairAdmin) {
        //get for the matching admin
        if (this.adminPair) {
          let modifiedAdmin = this.prepareAdminContractServiceForPosting(
            contractService,
            this.adminPair
          );
          postData.push(modifiedAdmin);
        }
      }
    }

    postData.push(contractService);

    this.endorsementService.postEndorsement(postData).subscribe(
      async (res: CommonResponse) => {
        if (res.responseCode == "00") {
          this.isSubmitted = false;
          this.messageService.add({
            severity: "success",
            summary: "Success",
            detail:
              "Endorsement processed and sent for approvals successfully.",
          });
          if (this.fileUploader) {
            this.fileUploader.clear();
          }
          this.form.reset();
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Failure",
            detail: res.responseMsg,
          });
        }
      },
      (error) => {
        this.messageService.add({
          severity: "error",
          summary: "Failed",
          detail: `${error.error.message}`,
        });
      }
    );
  }

  prepareAdminContractServiceForPosting(direct: any, admin: any) {
    admin.endorsementDescription = direct.endorsementDescription;
    admin.endorsementTypeId = direct.endorsementTypeId;
    admin.isRequestedForApproval = true;
    admin.customerDivisionId = direct.customerDivisionId;
    admin.isApproved = false;
    admin.isDeclined = false;
    admin.quantity = direct.quantity;
    admin.previousContractServiceId = admin.id;
    admin.contractId = direct.contractId;
    admin.documentUrl = direct.documentUrl;
    admin.daysAffected = direct.daysAffected;
    admin.dateForNewContractToTakeEffect =
      direct.dateForNewContractToTakeEffect;
    return this.getAdminAfterCalculation(admin);
  }

  getAdminAfterCalculation(admin: any) {
    const unitPrice = admin.unitPrice;
    const quantity = admin.quantity;
    let _discount = admin.discount ?? 0;
    if (_discount > 100) {
      _discount = 100;
      admin.discount = 100;
    }

    //const totalPrice = unitPrice * quantity;
    const totalPrice =
      (quantity * unitPrice * admin.daysAffected) / this.daysRatioTotal;
    let vat: number;
    if (admin?.service.isVatable) {
      vat = parseFloat((totalPrice * 0.075).toFixed(2));
    } else {
      vat = 0;
    }

    const billable = totalPrice - (_discount / 100) * totalPrice + vat;
    admin.billableAmount = billable;
    admin.budget = billable;
    admin.vat = vat;
    return admin;
  }

  amountParamChange() {
    var daysAffected = this.form.get("daysAffected").value;
    if (!daysAffected && this.creditDebitOrStandAlone == 1) return;

    //daysinmonths
    var enteredAmount;
    var intervalNumber = this.form.value.daysinmonths;

    if (!intervalNumber && this.creditDebitOrStandAlone == 1) return;

    if (this.creditDebitOrStandAlone == 1 && daysAffected > intervalNumber) {
      this.form.get("daysAffected").setErrors({ "invalid-days": true });
      this.daysAffectedError = `The days affected is not within maxiumum of ${intervalNumber} days in month(s)`;
      return;
    } else {
      this.form.get("daysAffected").setErrors(null);
    }

    this.daysRatioTotal = intervalNumber;

    if (this.creditDebitOrStandAlone == 1) {
      if (
        this.creditDebitOrStandAlone == 1 &&
        this.quantity &&
        this.unitPrice
      ) {
        const totalAmount =
          (this.quantity * this.unitPrice * daysAffected) / intervalNumber;
        this.vat = 0;
        if (this.contractServiceUnderEndorsement.service.isVatable) {
          this.vat = 0.075 * totalAmount;
        }

        enteredAmount = parseFloat(
          (
            totalAmount +
            this.vat -
            (this.discount / 100) * totalAmount
          ).toFixed(2)
        );

        this.form.patchValue({ amount: enteredAmount });

        if (enteredAmount <= 0) {
          this.endorsementEffect = "NO_CHANGE";
        } else {
          this.endorsementEffect = this.form.value.endorsementType;
        }
      }
    }
  }

  onUpload(event) {
    this.uploadedDocuments = [];
    for (const file of event.files) {
      this.uploadedDocuments.push(file);
    }

    this.messageService.add({
      severity: "success",
      summary: "Success",
      detail: "Document(s) Uploaded",
    });
  }

  selectFile(event, uploader: FileUpload) {
    this.fileUploader = uploader;
    this.onUpload(event);
  }

  onCancelUpload() {
    this.uploadedDocuments = [];
  }

  UpdateTotalAmount() {
    let amt = +this.form.get("amountWithoutVat").value;
    let vat = +this.form.get("vat").value;
    this.vat = vat;
    this.form.patchValue({
      amount: amt + vat,
    });
  }
}
