import { Component, OnInit } from "@angular/core";
import { AppMainComponent } from "./app.main.component";
import { RoleService } from "./services/role.service";
import { MessageService } from "primeng/api";
import { FireBaseAuthService } from "./services/fire-base-auth.service";
import { PassengerComponent } from "./home/armada/setup/passenger/passenger.component";
import { ArmedEscortRankComponent } from "./home/armada/setup/armed-escort-rank/armed-escort-rank.component";
import { ArmedEscortTypeComponent } from "./home/armada/setup/armed-escort-type/armed-escort-type.component";
import { ArmedEscortProfileComponent } from "./home/armada/setup/armed-escort-profile/armed-escort-profile.component";
import { SystemPermission } from "./interceptors/system.permission";
import { PermissionEnum, PermissionGroupEnum } from "./interfaces/permission";

@Component({
  selector: "app-menu",
  templateUrl: "./app.menu.component.html",
})
export class AppMenuComponent implements OnInit {
  model: any[];
  SystemPermission: SystemPermission;

  constructor(
    public app: AppMainComponent,
    public messageService: MessageService,
    public firebaseAuthService: FireBaseAuthService,
    public roleService: RoleService
  ) {}

  ngOnInit() {
    this.SystemPermission = new SystemPermission();
    this.setupMenu();
  }

  onMenuClick() {
    this.app.menuClick = true;
  }

  setupMenu() {
    const userRoles: any[] = this.firebaseAuthService.authUserRoles;
    this.model = [
      {
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        routerLink: ["home/login-dashboard"],
      },
      {
        label: "Dashboard",
        icon: "pi pi-fw pi-pencil",
        routerLink: ["/home"],
        visible: false,
        // visible: this.SystemPermission.HasPermissionGroup(
        //   PermissionGroupEnum.Management
        // ),
        items: [
          {
            label: "Management Dashboard",
            icon: "pi pi-fw pi-home",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.ManagementDashboard
            ),
            routerLink: ["home/managenement-dashboard"],
          },
          {
            label: "Production Dashboard",
            icon: "pi pi-fw pi-home",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Management_ProductionDashboard
            ),
            routerLink: ["home/production-dashboard"],
          },
        ],
      },
      {
        label: "Reporting",
        icon: "pi pi-fw pi-chart-bar",
        routerLink: ["/home"],
        visible:
          userRoles.some((x) => x.toLowerCase().includes("report")) ||
          userRoles.some((x) => x.toLowerCase().includes("reporting")) ||
          userRoles.some((x) => x.toLowerCase().includes("reports")) ||
          userRoles.some((x) => x.toLowerCase().includes("super")),
        items: [
          {
            label: "Reports",
            routerLink: ["/home/report-view"],
          },
          {
            label: "Receivables View",
            icon: "",
            routerLink: ["/home/report/receivable"],
          },
        ],
      },
      {
        label: "Users Service",
        icon: "pi pi-fw pi-user",
        routerLink: ["/home"],
        items: [
          { label: "User Profile", routerLink: ["/home/profile"] },
          {
            label: "Halogen users",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/halogen-users"],
            visible: userRoles.some((x) => x.toLowerCase().includes("super")),
          },
          {
            label: "Other users",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/other-users"],
            visible: userRoles.some((x) => x.toLowerCase().includes("super")),
          },
        ],
      },
      {
        label: "Business Settings",
        icon: "pi pi-fw pi-cog",
        routerLink: ["/home"],
        visible:
          userRoles.some((x) => x.toLowerCase().includes("setups")) ||
          userRoles.some((x) => x.toLowerCase().includes("super")),
        items: [
          {
            label: "Business Structure",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              {
                label: "Branch & Office",
                icon: "pi pi-fw pi-cog",
                routerLink: ["/home/account/branch-office"],
                visible: true,
              },
              {
                label: "Attach Users To Branch & Office",
                icon: "",
                routerLink: ["/home/account/attach-user-branch-office"],
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_BranchMemberAttachment
                ),
              },
              {
                label: "Attach Clients To Branch & Office",
                icon: "",
                routerLink: ["/home/account/attach-client-branch-office"],
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_BranchAttachment
                ),
              },
              {
                label: "Organization Setup",
                icon: "pi pi-fw pi-cog",
                routerLink: ["/home/division-op-setup"],
                visible: true,
              },
            ],
          },
          {
            label: "Approval Structure",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              {
                label: "Approval Checklist",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.System_ApprovalChecklist
                ),
                routerLink: ["home/approval-checklist"],
              },
              {
                label: "Approval Sequence",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.System_ApprovalSequence
                ),
                routerLink: ["home/approval-sequence"],
              },
              {
                label: "Approval Levels/Limits",
                routerLink: ["/home/approval-setup"],
                visible: true,
              },
              {
                label: "Alternate Approval Setup",
                routerLink: ["/home/approval-setup2"],
                visible: true,
              },
              {
                label: "Non-Principal Approval Setup",
                routerLink: ["/home/non-principal-approval-setup"],
                visible: true,
              },
              {
                label: "Contract Extension Approval Setup",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_CE_ApprovalPage
                ),
                routerLink: ["/home/client-management/ce-approval-setup"],
              },
              {
                label: "Client Merge Approval Setup",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_ClientMerge_ApprovalSetup
                ),
                routerLink: ["/home/client-management/client-merge"],
              },
              {
                label: "Finance Reversal Office Setup",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ReversalOffice
                ),
                routerLink: ["/home/reversal-office"],
              },
            ],
          },
          {
            label: "SBU Structure",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/services"],
            visible: true,
            items: [
              {
                label: "SBU Setup",
                icon: "",
                routerLink: ["/home/SBU"],
                visible: true,
              },
              {
                label: "Attach users to SBU",
                icon: "",
                routerLink: ["/home/sbu-users"],
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_SBU_User_Attachment
                ),
              },
              {
                label: "Attach Client To RM",
                icon: "",
                routerLink: ["/home/attach-client-to-rm"],
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_SBU_Client_Attachment
                ),
              },
            ],
          },
          {
            label: "Services",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/services"],
            visible: true,
            items: [
              {
                label: "Required Service Doc.",
                routerLink: ["/home/required-service-document"],
              },
              {
                label: "Required Service Qual. Element.",
                routerLink: ["/home/required-service-qualification-element"],
              },
              {
                label: "Service Category Task",
                routerLink: ["/home/service-category-task"],
              },
              {
                label: "Service Task Deliverable",
                routerLink: ["/home/service-task-deliverable"],
              },
              {
                label: "Service Type",
                routerLink: ["/home/service-type"],
              },
              {
                label: "Dtrack Income Account",
                routerLink: ["/home/service-income-account"],
              },
              { label: "Service", routerLink: ["/home/services"] },
              {
                label: "Service Pricing",
                routerLink: ["/home/service-pricing"],
              },
            ],
          },
          {
            label: "Bank Structure",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              {
                label: "Banks",
                icon: "pi pi-fw pi-cog",
                routerLink: ["/home/bank"],
                visible: true,
              },
              {
                label: "Bank Account",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_BankAccount
                ),
                routerLink: ["/home/setup/bank-account"],
              },
            ],
          },
          {
            label: "Qualification",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/qualification"],
            visible: true,
            items: [
              {
                label: "Group Type",
                routerLink: ["/home/qualification/group-type"],
              },
              {
                label: "Lead Type",
                routerLink: ["/home/qualification/lead-type"],
              },
              {
                label: "Relationship Type",
                routerLink: ["/home/qualification/relationship-type"],
              },
              {
                label: "Account Vouchers Type",
                routerLink: ["/home/qualification/account-voucher-type"],
              },
              {
                label: "Means Of Identification",
                routerLink: ["/home/qualification/means-of-identification"],
              },
              {
                label: "Lead Origin",
                routerLink: ["/home/qualification/lead-origin"],
              },
              {
                label: "Mode Of Transport",
                icon: "pi pi-fw pi-cog",
                routerLink: ["/home/modeoftransport"],
                visible: true,
              },
              {
                label: "Currency",
                routerLink: ["/home/currency-setup"],
                visible: true,
              },
              {
                label: "Business Target",
                icon: "pi pi-fw pi-cog",
                routerLink: ["/home/target"],
                visible: true,
              },
              {
                label: "Endorsement Type",
                icon: "pi pi-fw pi-cog",
                routerLink: ["/home/qualification/endorsement-type"],
                visible: true,
              },
            ],
          },
          {
            label: "CRM Structure",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              {
                label: "Contract Renew Notification",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_Contract_Renew_Notification
                ),
                routerLink: [
                  "/home/client-management/contract-renew-notification",
                ],
              },
              {
                label: "Auto-Renew Policy",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_AutoRenewPolicy
                ),
                routerLink: ["/home/finance/auto-renew-policy"],
              },
              {
                label: "Corporate Adjustment",
                routerLink: ["/home/corporate-adjustment-setup"],
                visible: true,
              },
              {
                label: "Standard SLA",
                routerLink: ["/home/standard-sla-for-operation"],
                visible: true,
              },
              {
                label: "Service Cost Analysis",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_Service_Cost_Analysis
                ),
                routerLink: ["/home/client-management/service-cost-analysis"],
              },
            ],
          },
          {
            label: "GMA Structure",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              {
                label: "Beat Type Setup",
                routerLink: ["/home/client-management/beat-type-setup"],
              },
              {
                label: "Beat Creation",
                routerLink: ["/home/client-management/beat-creation"],
              },
              {
                label: "Location Check-list Setup",
                routerLink: [
                  "/home/client-management/location-checklist-setup",
                ],
              },
            ],
          },
          {
            label: "Expense Structure",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              {
                label: "Expense Line Setup",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_ExpenseLineSetup
                ),
                routerLink: ["/home/finance/expense/line-setup"],
              },
              {
                label: "Approval Set-up",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_ApprovalSetup
                ),
                routerLink: ["/home/finance/expense/approval-setup"],
              },
              {
                label: "Authorization Set-up",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_AuthorizationSetup
                ),
                routerLink: ["/home/finance/expense/authorization-setup"],
              },
            ],
          },
          {
            label: "Complaint Structure",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/complaint-setup"],
            visible: true,
          },
        ],
      },
      {
        label: "Supplier",
        icon: "pi pi-fw pi-cog",
        routerLink: ["/home"],
        visible:
          userRoles.some((x) => x.toLowerCase().includes("supplier")) ||
          userRoles.some((x) => x.toLowerCase().includes("super")),
        items: [
          {
            label: "Supplier",
            icon: "pi pi-fw pi-briefcase",
            routerLink: ["home/supplier"],
          },

          {
            label: "Categories",
            icon: "pi pi-fw pi-th-large",
            routerLink: ["home/supplier/category"],
          },

          {
            label: "Product & Services",
            icon: "pi pi-fw pi-clone",
            routerLink: ["home/supplier/service"],
          },
          {
            label: "Supplier approval request",
            icon: "pi pi-fw pi-check",
            routerLink: ["/home/supplier-approval-request"],
            visible: true,
          },
          {
            label: "supplier approval listing",
            icon: "pi pi-fw pi-list",
            routerLink: ["/home/supplier-approval-listing"],
            visible: true,
          },
          {
            label: "Settings",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/supplier/settings"],
            items: [
              {
                label: "Make",
                icon: "pi pi-fw pi-tag",
                routerLink: ["/home/supplier/settings/make"],
              },
              {
                label: "Model",
                icon: "pi pi-fw pi-tags",
                routerLink: ["/home/supplier/settings/model"],
              },
              {
                label: "Supplier approval office Setup",
                icon: "pi pi-fw pi-cog",
                routerLink: ["/home/supplier-office-setup"],
                visible: true,
              },
            ],
          },
          {
            label: "Bank Account",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Supplier_BankAccounts
            ),
            routerLink: ["/home/supplier/bank-account"],
          },
        ],
      },
      {
        label: "Role Management",
        icon: "pi pi-fw pi-user-edit",
        routerLink: ["/home"],
        visible:
          userRoles.some((x) => x.toLowerCase().includes("role")) ||
          userRoles.some((x) => x.toLowerCase().includes("super")),
        items: [{ label: "Roles", routerLink: ["/home/role"] }],
      },
      {
        label: "Lead Administration Management",
        icon: "pi pi-fw pi-user-plus",
        routerLink: ["/home"],
        visible:
          userRoles.some((x) => x.toLowerCase().includes("lead")) ||
          userRoles.some((x) => x.toLowerCase().includes("leads")) ||
          userRoles.some((x) => x.toLowerCase().includes("super")),
        items: [
          {
            label: "Unqualified Leads",
            icon: "pi pi-fw pi-id-card",
            routerLink: ["/home/suspect-capture"],
          },

          {
            label: "Contacts",
            icon: "pi pi-fw pi-users",
            routerLink: ["/home/contacts"],
          },
          {
            label: "Lead Engagements",
            icon: "pi pi-fw pi-comments",
            routerLink: ["/home/lead-engagements"],
          },
          {
            label: "Opportunity Mgt",
            icon: "pi pi-fw pi-thumbs-up",
            routerLink: ["/home/lead-admin"],
          },
          {
            label: "Lead Qualification",
            icon: "pi pi-fw pi-comment",
            routerLink: ["/home/suspect-qualification"],
          },

          // {
          //   label: "Opportunities",
          //   icon: "pi pi-fw pi-users",
          //   routerLink: ["/home/lead-admin"],
          // },

          {
            label: "Pipeline Views",
            icon: "pi pi-fw pi-angle-double-right",
            visible: true,
            items: [
              {
                label: "Qualification",
                icon: "pi pi-fw pi-comment",
                routerLink: ["/home/qualification-pipeline"],
              },
              {
                label: "Oppportunity",
                icon: "pi pi-fw pi-thumbs-up",
                routerLink: ["/home/opportunities-pipeline"],
              },
            ],
          },
          // {
          //   label: "Lead Dashboard",
          //   icon: "pi pi-fw pi-users",
          //   routerLink: ["/home/lead-dashboard"],
          // },
        ],
      },
      {
        label: "Project Management",
        icon: "pi pi-fw pi-tags",
        routerLink: ["/home"],
        visible:
          userRoles.some((x) => x.toLowerCase().includes("project")) ||
          userRoles.some((x) => x.toLowerCase().includes("projects")) ||
          userRoles.some((x) => x.toLowerCase().includes("super")),
        items: [
          {
            label: "Project Allocation and Assignment",
            icon: "pi pi-fw pi-paperclip",
            routerLink: [
              "/home/workspace-management/project-manager-allocation",
            ],
            visible: true,
          },
          {
            label: "Project Management Structure",
            icon: "pi pi-fw pi-folder",
            routerLink: ["/home/workspace-management/create-workspace"],
            visible: true,
          },
          {
            label: "Task  Management",
            icon: "pi pi-fw pi-list",
            routerLink: ["/home/workspace-management/task-manager"],
            visible: true,
          },
          {
            label: "Deliverable  Management",
            icon: "pi pi-fw pi-list",
            routerLink: ["/home/workspace-management/deliverable-management"],
            visible: true,
          },

          {
            label: "Deliverable Approvals",
            icon: "pi pi-fw pi-check-circle",
            routerLink: ["/home/workspace-management/approval-management"],
            visible: true,
          },
          {
            label: "Watcher Views",
            icon: "pi pi-fw pi-id-card",
            visible: true,
            items: [
              {
                label: "Workspace Tracker",
                icon: "pi pi-fw pi-desktop",
                routerLink: ["/home/workspace-management/workspace-tracker"],
                visible: true,
              },
              {
                label: "Project Tracker",
                icon: "pi pi-fw pi-desktop",
                routerLink: ["/home/workspace-management/project-tracking"],
                visible: true,
              },
              {
                label: "Task Tracker",
                icon: "pi pi-fw pi-desktop",
                routerLink: ["/home/workspace-management/task-dashboard"],
                visible: true,
              },
              {
                label: "Deliverable  Tracker",
                icon: "pi pi-fw pi-desktop",
                routerLink: ["/home/deliverable-dashboard"],
                visible: true,
              },
            ],
          },
        ],
      },
      {
        label: "Approval Management",
        icon: "pi pi-fw pi-pencil",
        routerLink: ["/home"],
        visible: this.SystemPermission.HasPermissionGroup(
          PermissionGroupEnum.System
        ),
        items: [
          {
            label: "CRM Approvals",
            visible: true,
            items: [
              {
                label: "Approval Dashboard",
                icon: "pi pi-fw pi-home",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Management_ApprovalDashboard
                ),
                routerLink: ["home/approval-dashboard"],
              },
              {
                label: "Approvals",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.System_SingleApproval
                ),
                routerLink: ["/home/approvals"],
              },
              {
                label: "Bulk Approval",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.System_BulkApproval
                ),
                routerLink: ["/home/bulk-approval"],
              },
              {
                label: "Extension Approval",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_CE_Approval
                ),
                routerLink: ["/home/client-management/ce-approval"],
              },
              {
                label: "Client Megre Approval",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_ClientMerge_Approval
                ),
                routerLink: ["/home/client-management/client-merge-approval"],
              },
            ],
          },
          {
            label: "Inventory Line Manager Approval",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Organization_LineManagerApproval
            ),
            routerLink: ["/home/organization/line-manager-approval"],
          },
          {
            label: "Expense Approvals",
            visible: true,
            items: [
              {
                label: "Line Manager Approval",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_LineApproval
                ),
                routerLink: ["/home/organization/expense-line-approval"],
              },
              {
                label: "Workflow Approval",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_Approval
                ),
                routerLink: ["/home/finance/expense/approval"],
              },
            ],
          },
        ],
      },
      {
        label: "Production Views",
        icon: "pi pi-fw pi-pencil",
        routerLink: ["/home"],
        visible: true,
        items: [
          {
            label: "Production Dashboard",
            routerLink: ["/home/sales-dashboard"],
          },
        ],
      },
      {
        label: "Finance",
        icon: "pi pi-fw pi-chart-line",
        routerLink: ["/home"],
        visible:
          userRoles.some((x) => x.toLowerCase().includes("finance")) ||
          userRoles.some((x) => x.toLowerCase().includes("super")),
        items: [
          { label: "Chart of Accounts", routerLink: ["/home/account-chart"] },
          {
            label: "Account Statements",
            routerLink: ["/home/account-statement"],
          },
          {
            label: "Account Transaction Master/Details",
            routerLink: ["/home/account-transaction-md"],
          },
          {
            label: "Receipting",
            icon: "pi pi-fw pi-id-card",
            visible: true,
            items: [
              { label: "Receipts Capture", routerLink: ["/home/receipting"] },
              {
                label: "Advance Payment Capture",
                routerLink: ["/home/advance-payment"],
              },
              {
                label: "Bulk Settlement",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_BulkSettlement
                ),
                routerLink: ["/home/finance/bulk-settlement"],
              },
              {
                label: "Raw Inflow Upload",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_RawInflowUpload
                ),
                routerLink: ["/home/finance/raw-inflow-upload"],
              },
            ],
          },
          {
            label: "GL Postings",
            icon: "pi pi-fw pi-id-card",
            visible: true,
            items: [
              {
                label: "Voucher Posting",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_VoucherPosting
                ),
                routerLink: ["/home/finance/voucher-posting"],
              },
              {
                label: "Invoice Update",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_InvoiceUpdate
                ),
                routerLink: ["/home/finance/invoice-update"],
              },
            ],
          },
          {
            label: "Group Reversals",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.Finance_Reversals
            ),
            items: [
              {
                label: "Reverse Receipts",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_Reversal_ReverseReceipts
                ),
                routerLink: ["/home/reverse-receipting"],
              },
              {
                label: "Reverse Invoice",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_Reversal_ReverseInvoice
                ),
                routerLink: ["/home/reverse-invoice"],
              },
              {
                label: "Reverse Voucher",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_Reversal_ReverseVoucher
                ),
                routerLink: ["/home/reverse-voucher"],
              },
              {
                label: "Reverse Advance Payment",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_AdvancePayment_Reversal
                ),
                routerLink: ["/home/finance/advance-payment/reversal"],
              },
              {
                label: "Credit Note Reversal",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_CreditNoteReversal
                ),
                routerLink: ["/home/finance/credit-note-reversal"],
              },
            ],
          },
          {
            label: "Reconciliation",
            icon: "pi pi-fw pi-id-card",
            visible: true,
            items: [
              {
                label: "Paystack Reconciliation",
                // visible: this.SystemPermission.HasPermission(
                //   PermissionEnum.Account_Reconciliation
                // ),
                routerLink: ["/home/finance/account-reconciliation"],
              },
              {
                label: "Dtrack Transmission",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_DtrackTransmission
                ),
                routerLink: ["/home/finance/dtrack-transmission"],
              },
            ],
          },
          {
            label: "Period Opening/Closing",
            routerLink: ["/home/period-opening-closing"],
          },
        ],
      },
      {
        label: "Expense MGT",
        icon: "pi pi-fw pi-id-card",
        routerLink: ["/home"],
        visible: this.SystemPermission.HasPermissionGroup(
          PermissionGroupEnum.Finance_ExpenseTool
        ),
        items: [
          {
            label: "Budget",
            routerLink: ["/home"],
            visible: true,
            items: [
              {
                label: "Expense Budget",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_ExpenseBudget
                ),
                routerLink: ["/home/finance/expense/budgets"],
              },
            ],
          },
          {
            label: "Request & Tracking",
            routerLink: ["/home"],
            visible: true,
            items: [
              {
                label: "User Request",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_UserRequest
                ),
                routerLink: ["/home/finance/expense/user-request"],
              },
              {
                label: "Request Tracker",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_RequestTracker
                ),
                routerLink: ["/home/finance/expense/request-tracker"],
              },
            ],
          },
          {
            label: "Authorization",
            routerLink: ["/home"],
            visible: true,
            items: [
              {
                label: "Expense Line Authorization",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_Authorization
                ),
                routerLink: ["/home/finance/expense/authorization"],
              },
            ],
          },
          {
            label: "Payments",
            routerLink: ["/home"],
            visible: true,
            items: [
              {
                label: "Payables",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_Payables
                ),
                routerLink: ["/home/finance/expense/payables"],
              },
              {
                label: "Payments Processing",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_Payment
                ),
                routerLink: ["/home/finance/expense/payment"],
              },
              {
                label: "Print Cheque",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_PrintCheque
                ),
                routerLink: ["/home/finance/expense/print-cheque"],
              },
              {
                label: "Bank Payment",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_ExpenseTool_BankPayment
                ),
                routerLink: ["/home/finance/expense/bank-payment"],
              },
            ],
          },
          {
            label: "Requester Attachment",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Finance_ExpenseTool_RequesterAttachment
            ),
            routerLink: ["/home/finance/expense/requester-attachment"],
          },
        ],
      },
      {
        label: "Client Management",
        icon: "pi pi-fw pi-id-card",
        routerLink: ["/home"],
        visible:
          userRoles.some((x) => x.toLowerCase().includes("client")) ||
          userRoles.some((x) => x.toLowerCase().includes("clients")) ||
          userRoles.some((x) => x.toLowerCase().includes("super")),
        items: [
          {
            label: "Client View",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              { label: "360 Client View", routerLink: ["/home/client-view"] },
            ],
          },
          {
            label: "Contract & Billing",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              {
                label: "Client Endorsement",
                routerLink: ["/home/client-endorsement"],
                visible: true,
              },
              {
                label: "AdHoc Invoice",
                routerLink: ["/home/invoice-creation"],
              },
              {
                label: "Contract Extension",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_CE_Request
                ),
                routerLink: ["/home/client-management/ce-request"],
              },
              {
                label: "Client Merge Request",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_ClientMerge_Request
                ),
                routerLink: ["/home/client-management/client-merge-request"],
              },
              {
                label: "Invoice Replacements",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_InvoiceReplacement
                ),
                routerLink: ["/home/finance/invoice-replacement"],
              },
              {
                label: "Express Profoma Invoice",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_ExpressProfomaInvoice
                ),
                routerLink: ["/home/finance/express-performa-inv"],
              },
              {
                label: "Express Profoma CloseOut",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_ExpressProfomaCloseOut
                ),
                routerLink: ["/home/finance/express-performa-closeout"],
              },
            ],
          },
          {
            label: "Beneficiary",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              { label: "Add Beneficiaries", routerLink: ["/home/beneficiary"] },
            ],
          },
          {
            label: "Views & Updates",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              { label: "Client Listing", routerLink: ["/home/client-listing"] },
              {
                label: "Armotization View",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_AmortizationView
                ),
                routerLink: [
                  "/home/client-management/revenue-armotization-view",
                ],
              },
              {
                label: "Renewal View",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_RenewalView
                ),
                routerLink: ["/home/client-management/renewal-view"],
              },
              {
                label: "Information Update",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Client_InformationUpdate
                ),
                routerLink: ["/home/client-management/information-update"],
              },
            ],
          },
          {
            label: "Reconciliation",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/"],
            visible: true,
            items: [
              {
                label: "Raw Inflow Confirmation",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Finance_RawInflowConfirmation
                ),
                routerLink: ["/home/finance/raw-inflow-confirmation"],
              },
            ],
          },
          {
            label: "Client Policy Setting",
            routerLink: ["/home/client-policy"],
          },
        ],
      },
      {
        label: "Response",
        icon: "pi pi-fw pi-id-card",
        routerLink: ["/home"],
        visible:
          userRoles.some((x) => x.toLowerCase().includes("response")) ||
          //userRoles.some((x) => x.toLowerCase().includes("mobility")) ||
          userRoles.some((x) => x.toLowerCase().includes("super")) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.Response
          ),
        items: [
          {
            label: "Response Settings",
            //  visible: this.SystemPermission.HasPermission(
            //   PermissionEnum.Response_TeamType_Setup
            // ),
            routerLink: ["/home/response/response-team-type"],
          },
          {
            label: "Response Team",
            // visible: this.SystemPermission.HasPermission(
            //   PermissionEnum.Response_Team_Setup
            // ),
            routerLink: ["/home/response/response-teams"],
          },
          {
            label: "Resting Station Setup",
            // visible: this.SystemPermission.HasPermission(
            //   PermissionEnum.Resting_Station_Setup
            // ),
            routerLink: ["/home/response/resting-stations-setup"],
          },
          {
            label: "Resting Station LGA Map",
            // visible: this.SystemPermission.HasPermission(
            //   PermissionEnum.Resting_Station_LGA_Map_Setup
            // ),
            routerLink: ["/home/response/resting-station-lga-map"],
          },
          {
            label: "Response Team-Resting Station Map",
            // visible: this.SystemPermission.HasPermission(
            //   PermissionEnum.Response_Team_Resting_Station_Map
            // ),
            routerLink: ["/home/response/resp-team-reststation-map"],
          },
        ],
      },
      {
        label: "Secured Mobility",
        icon: "pi pi-fw pi-user",
        routerLink: ["/home"],
        visible:
          userRoles.some((x) => x.toLowerCase().includes("secured")) ||
          userRoles.some((x) => x.toLowerCase().includes("mobility")) ||
          userRoles.some((x) => x.toLowerCase().includes("super")) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.SecuredMobility
          ),
        items: [
          // {
          //   label: "Dashboard",
          //   icon: "pi pi-fw pi-id-card",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_Dashboard
          //   ),
          //   routerLink: ["/home/armada/active-service"],
          // },
          // {
          //   label: "Status Dashboard",
          //   icon: "pi pi-fw pi-id-card",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_Dashboard
          //   ),
          //   routerLink: ["/home/armada/status-dashboard"],
          // },
          {
            label: "Dashboard",
            icon: "pi pi-fw pi-cog",

            items: [
              {
                label: "Dashboard",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_Dashboard
                ),
                routerLink: ["/home/armada/active-service"],
              },
              {
                label: "Status Dashboard",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_Dashboard
                ),
                routerLink: ["/home/armada/status-dashboard"],
              },
            ],
          },
          {
            label: "Mobility Settings",
            icon: "pi pi-fw pi-cog",

            items: [
              {
                label: "Resource Type Settings",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_ResourceType
                ),
                routerLink: ["/home/armada/setup/resource-type-setup"],
              },
              {
                label: "Rank Settings",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_ResourceRank
                ),
                routerLink: ["/home/armada/setup/rank-settings"],
              },
            ],
          },

          {
            label: "Resources Setup",
            icon: "pi pi-fw pi-cog",

            items: [
              {
                label: "Resource Registration",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_ResourceRegistration
                ),
                routerLink: ["/home/armada/setup/resource-registration"],
              },
              {
                label: "Resource Look-Up",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_ResourceLookUp
                ),
                routerLink: ["/home/armada/setup/resource-lookup"],
              },
              {
                label: "Vehicle Driver Mapping",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_VehicleDriverMap
                ),
                routerLink: ["/home/armada/setup/vehicle-driver-mapping"],
              },
              {
                label: "Resource Tie",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_ResourceTie
                ),
                routerLink: ["/home/armada/setup/resource-tie"],
              },
              {
                label: "Resource Scheduling",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_ResourceScheduling
                ),
                routerLink: ["/home/armada/setup/resource-scheduling"],
              },
            ],
          },

          {
            label: "Route Setup",
            icon: "pi pi-fw pi-cog",

            items: [
              {
                label: "Route & Region Segment",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_SMORouteAndRegion
                ),
                routerLink: ["/home/armada/setup/routeregion-segment"],
              },
              {
                label: "Route & State map",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_RouteStateMap
                ),
                routerLink: ["/home/armada/setup/route-state-maps"],
              },
            ],
          },
          {
            label: "Services Setup",
            icon: "pi pi-fw pi-cog",

            items: [
              {
                label: "Service Registration",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_ServiceRegistration
                ),
                routerLink: ["/home/armada/setup/service-registration"],
              },
              {
                label: "Business Rule",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_BusinessRule
                ),
                routerLink: ["/home/armada/setup/business-rule"],
              },
            ],
          },

          {
            label: "Prices Setup",
            icon: "pi pi-fw pi-cog",

            items: [
              {
                label: "Price Register",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_PriceRegister
                ),
                routerLink: ["/home/armada/setup/price-register"],
              },
              {
                label: "Preferential Pricing",
                icon: "pi pi-fw pi-cog",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Secured_Mobility_PreferentialPricing
                ),
                routerLink: ["/home/armada/setup/preferential-pricing"],
              },
            ],
          },

          /////
          // {
          //   label: "Resource Registration",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_ResourceRegistration
          //   ),
          //   routerLink: ["/home/armada/setup/resource-registration"],
          // },
          // {
          //   label: "Resource Look-Up",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_ResourceLookUp
          //   ),
          //   routerLink: ["/home/armada/setup/resource-lookup"],
          // },
          // {
          //   label: "Route & Region Segment",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_SMORouteAndRegion
          //   ),
          //   routerLink: ["/home/armada/setup/routeregion-segment"],
          // },
          // {
          //   label: "Route & State map",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_RouteStateMap
          //   ),
          //   routerLink: ["/home/armada/setup/route-state-maps"],
          // },
          // {
          //   label: "Vehicle Driver Mapping",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_VehicleDriverMap
          //   ),
          //   routerLink: ["/home/armada/setup/vehicle-driver-mapping"],
          // },
          // {
          //   label: "Service Registration",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_ServiceRegistration
          //   ),
          //   routerLink: ["/home/armada/setup/service-registration"],
          // },
          // {
          //   label: "Business Rule",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_BusinessRule
          //   ),
          //   routerLink: ["/home/armada/setup/business-rule"],
          // },
          // {
          //   label: "Price Register",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_PriceRegister
          //   ),
          //   routerLink: ["/home/armada/setup/price-register"],
          // },
          // {
          //   label: "Preferential Pricing",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_PreferentialPricing
          //   ),
          //   routerLink: ["/home/armada/setup/preferential-pricing"],
          // },
          // {
          //   label: "Resource Tie",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_ResourceTie
          //   ),
          //   routerLink: ["/home/armada/setup/resource-tie"],
          // },
          // {
          //   label: "Resource Scheduling",
          //   icon: "pi pi-fw pi-cog",
          //   visible: this.SystemPermission.HasPermission(
          //     PermissionEnum.Secured_Mobility_ResourceScheduling
          //   ),
          //   routerLink: ["/home/armada/setup/resource-scheduling"],
          // },
          {
            label: "Service Assignment",
            icon: "pi pi-fw pi-cog",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Secured_Mobility_ServiceAssignment
            ),
            routerLink: ["/home/armada/setup/master-service-assignment"],
          },
          {
            label: "Journey Start & Stop",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/armada/setup/journey-start"],
          },
          {
            label: "Admin Start & End Journey",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/armada/setup/admin-end-journey"],
          },
          // {
          //   label: "Journey start & Stop",
          //   icon: "pi pi-fw pi-cog",
          //   items: [
          //     {
          //       label: "Journey Start & Stop",
          //       icon: "pi pi-fw pi-cog",
          //       visible: this.SystemPermission.HasPermission(
          //         PermissionEnum.Secured_Mobility_JourneystartStop
          //       ),
          //       routerLink: ["/home/armada/setup/journey-start"],
          //     },
          //     {
          //       label: "Admin End Journey",
          //       icon: "pi pi-fw pi-cog",
          //       visible: this.SystemPermission.HasPermission(
          //         PermissionEnum.Secured_Mobility_AdminEndJourney
          //       ),
          //       routerLink: ["/home/armada/setup/admin-end-journey"],
          //     },
          //   ],
          // },

          // {
          //   label: "Situation Type",
          //   icon: "pi pi-fw pi-id-card",
          //   routerLink: ["/home/armada/setup/situation-type"],
          // },
          // {
          //   label: "Commander Ranks",
          //   icon: "pi pi-fw pi-id-card",
          //   routerLink: ["/home/armada/setup/commander-rank"],
          // },
          // {
          //   label: "Commander Registration",
          //   icon: "pi pi-fw pi-id-card",
          //   routerLink: [
          //     "/home/armada/mobility-administration/commander-registration",
          //   ],
          // },
          // {
          //   label: "Service Assignment",
          //   icon: "pi pi-fw pi-id-card",
          //   routerLink: [
          //     "/home/armada/mobility-administration/service-request",
          //   ],
          // },
          // {
          //   label: "My Journeys",
          //   icon: "pi pi-fw pi-id-card",
          //   routerLink: [
          //     "/home/armada/mobility-administration/my-completed-journey",
          //   ],
          // },
          // {
          //   label: "Journey",
          //   icon: "pi pi-fw pi-id-card",
          //   routerLink: ["/home/armada/mobility-administration/close-journey"],
          // },
        ],
      },
      {
        label: "Complaint Management",
        icon: "pi pi-fw pi-cog",
        visible: this.SystemPermission.HasPermissionGroup(
          PermissionGroupEnum.Complaint
        ),
        items: [
          {
            label: "Register Complaints",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Complaints_Registration
            ),
            routerLink: ["/home/complaint-management/register-complaint"],
          },
          {
            label: "Complaint Handling",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Complaints_Handling
            ),
            routerLink: ["/home/complaint-management/complaint-handling"],
          },
          {
            label: "Complaint Tracking",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Complaints_Tracking
            ),
            routerLink: ["/home/complaint-management/complaint-tracking"],
          },
          {
            label: "Escalation Management",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Complaints_EscalationManagement
            ),
            routerLink: ["/home/complaint-management/escalation-management"],
          },
        ],
      },
      {
        label: "Inventory Management",
        icon: "pi pi-fw pi-cog",
        visible: this.SystemPermission.HasPermissionGroup(
          PermissionGroupEnum.Inventory
        ),
        items: [
          {
            label: "Dashboard",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_Dashboard
            ),
            routerLink: ["/home/inventory-management/dashboard"],
          },
          {
            label: "Set-up",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_Setup
            ),
            routerLink: ["/home/inventory-management/set-up"],
          },
          {
            label: "Inventory Store",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_InventoryStore
            ),
            routerLink: ["/home/inventory-management/inventory-store"],
          },
          {
            label: "Central Request",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_CentralRequest
            ),
            routerLink: ["/home/inventory-management/central-request"],
          },
          {
            label: "Regional Request",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_RegionalRequest
            ),
            routerLink: ["/home/inventory-management/regional-request"],
          },
          {
            label: "User Request",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_UserRequest
            ),
            routerLink: ["/home/inventory-management/user-request"],
          },
          {
            label: "Batch Approval",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_BatchApproval
            ),
            routerLink: ["/home/inventory-management/batch-approval"],
          },
          {
            label: "Batch Confirmation",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_BatchConfirmation
            ),
            routerLink: ["/home/inventory-management/batch-confirmation"],
          },
          {
            label: "Stock Policy",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_StockPolicy
            ),
            routerLink: ["/home/inventory-management/stock-policy"],
          },
          {
            label: "Request Audit",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_RequestAudit
            ),
            routerLink: ["/home/inventory-management/request-audit"],
          },
          {
            label: "Stock Position",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_StockPosition
            ),
            routerLink: ["/home/inventory-management/stock-position"],
          },
          {
            label: "Stock Receipt",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_StockReceipt
            ),
            routerLink: ["/home/inventory-management/stock-receipt"],
          },
          {
            label: "Stock Issue",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_StockIssue
            ),
            routerLink: ["/home/inventory-management/stock-issue"],
          },
          {
            label: "Batch Request Tracking",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_BatchRequestTracking
            ),
            routerLink: ["/home/inventory-management/batch-request-tracking"],
          },
          {
            label: "Aggregation View",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Inventory_AggregatedView
            ),
            routerLink: ["/home/inventory-management/aggregated-view"],
          },
        ],
      },
      {
        label: "Guard Management",
        icon: "pi pi-fw pi-cog",
        visible:
          this.SystemPermission.HasPermissionGroup(PermissionGroupEnum.GMA) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.GMA_Settings
          ) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.GMA_JobAndOperatives
          ) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.GMA_Materials
          ) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.GMA_OgranizationStructure
          ) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.GMA_Listings
          ) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.GMA_Supervision
          ),
        items: [
          {
            label: "Dashboard",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.GMA_Dashboard
            ),
            routerLink: ["/home/guard-management/dashboard"],
          },
          {
            label: "Settings",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/guard-management"],
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.GMA_Settings
            ),
            items: [
              {
                label: "GMA Settings",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_Settings
                ),
                routerLink: ["/home/guard-management/settings"],
              },
              {
                label: "Guard Items Set-up",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_ItemSetup
                ),
                routerLink: ["/home/guard-management/item-settings"],
              },
              {
                label: "GMA Set-up",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_Setup
                ),
                routerLink: ["/home/guard-management/setup"],
              },
            ],
          },
          {
            label: "Jobs and Operatives",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/guard-management"],
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.GMA_JobAndOperatives
            ),
            items: [
              {
                label: "Create Job",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_CreateJob
                ),
                routerLink: ["/home/guard-management/create-job"],
              },
              {
                label: "GMA Manage Job",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_ManageJob
                ),
                routerLink: ["/home/guard-management/manage-job"],
              },
              {
                label: "GMA Redeployment",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_Redeployment
                ),
                routerLink: ["/home/guard-management/gma-redeployment"],
              },
              {
                label: "Operative Management",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_OperativeManagement
                ),
                routerLink: ["/home/guard-management/gma-operative-management"],
              },
              {
                label: "GMA Training School",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_TrainingSchool
                ),
                routerLink: ["/home/guard-management/training-school"],
              },
              {
                label: "GMA Re-accreditation",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_Reaccreditation
                ),
                routerLink: ["/home/guard-management/gma-bio-recapture"],
              },
              {
                label: "Standalone Guard Registration",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_GuardRegistration
                ),
                routerLink: ["/home/guard-management/guard-registration"],
              },
            ],
          },
          {
            label: "Materials",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/guard-management"],
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.GMA_Materials
            ),
            items: [
              {
                label: "GMA Material Request",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_MaterialRequest
                ),
                routerLink: ["/home/guard-management/material-request"],
              },
              {
                label: "GMA Material Refresh",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_MaterialRefresh
                ),
                routerLink: ["/home/guard-management/material-refresh"],
              },
              {
                label: "Material Replacement",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_MaterialReplacement
                ),
                routerLink: ["/home/guard-management/material-replace"],
              },
              {
                label: "GMA Material Distribution",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_MaterialDistribution
                ),
                routerLink: [
                  "/home/guard-management/gma-material-distribution",
                ],
              },
            ],
          },
          {
            label: "Supervision",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/guard-management"],
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.GMA_Supervision
            ),
            items: [
              {
                label: "GMA Supervision Policy",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_SupervisionPolicy
                ),
                routerLink: ["/home/guard-management/gma-supervision-policy"],
              },
              {
                label: "GMA Cluster Reporting",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_ClusterReporting
                ),
                routerLink: ["/home/guard-management/gma-cluster-reporting"],
              },
              {
                label: "Station Absence Requests",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_StationAbsenceRequest
                ),
                routerLink: ["/home/payroll/station-absence-req"],
              },
              {
                label: "Station Absence Approvals",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_StationAbsenceApproval
                ),
                routerLink: ["/home/payroll/station-absence-app"],
              },
            ],
          },
          {
            label: "Organization Structure",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/guard-management"],
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.GMA_OgranizationStructure
            ),
            items: [
              {
                label: "GMA Zonal Map View",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_ZonalMapView
                ),
                routerLink: ["/home/guard-management/gma-zonal-map-view"],
              },
              {
                label: "GMA Org Structure",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_OrgStructure
                ),
                routerLink: ["/home/guard-management/org-structure"],
              },
              {
                label: "GMA Location",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_Location
                ),
                routerLink: ["/home/client-management/client-location"],
              },
              {
                label: "GMA Beat Allocation",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_BeatAllocation
                ),
                routerLink: ["/home/guard-management/gma-beat-allocation"],
              },
            ],
          },
          {
            label: "Listings",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/guard-management"],
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.GMA_Listings
            ),
            items: [
              {
                label: "GMA Guarantor",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_Guarantor
                ),
                routerLink: ["/home/guard-management/guarantor"],
              },
              {
                label: "GMA Facilitator",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_Facilitator
                ),
                routerLink: ["/home/guard-management/facilitator"],
              },
              {
                label: "Training Facility",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_TrainingFacility
                ),
                routerLink: ["/home/guard-management/training-facility"],
              },
              {
                label: "Applicant Listing",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.GMA_ApplicantListing
                ),
                routerLink: ["/home/guard-management/applicant-listing"],
              },
            ],
          },
          {
            label: "GMA Approval",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.GMA_Approval
            ),
            routerLink: ["/home/guard-management/approval"],
          },
          {
            label: "Training Schedule",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.GMA_TrainingSchedule
            ),
            routerLink: ["/home/guard-management/training-schedule"],
          },
          {
            label: "GMA Sanction",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.GMA_Sanction
            ),
            routerLink: ["/home/guard-management/sanctions"],
          },
          {
            label: "GMA Bio-Search",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.GMA_BioSearch
            ),
            routerLink: ["/home/guard-management/bio-search"],
          },
        ],
      },
      {
        label: "Payroll Management",
        icon: "pi pi-fw pi-cog",
        visible:
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.Payroll
          ) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.Payroll_Settings
          ) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.Payroll_PayrollViews
          ),
        items: [
          {
            label: "Settings",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/payroll"],
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.Payroll_Settings
            ),
            items: [
              {
                label: "Generic Settings",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Payroll_GenericSettings
                ),
                routerLink: ["/home/payroll/payroll-settings"],
              },
              {
                label: "Special Settings",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Payroll_SpecialSettings
                ),
                routerLink: ["/home/payroll/special-setup"],
              },
              {
                label: "Tax Setting",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Payroll_TaxSettings
                ),
                routerLink: ["/home/payroll/tax-settings"],
              },
            ],
          },
          {
            label: "Pay Grade",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_PayGrade
            ),
            routerLink: ["/home/payroll/pay-grade"],
          },
          {
            label: "Pay Group",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_PayGroup
            ),
            routerLink: ["/home/payroll/pay-group"],
          },
          {
            label: "Operative Attachment",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_OperativeAttachment
            ),
            routerLink: ["/home/payroll/operative-attachment"],
          },
          {
            label: "Payroll Runs",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_PayrollRuns
            ),
            routerLink: ["/home/payroll/runs"],
          },
          {
            label: "Payroll Management",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_PayrollManagement
            ),
            routerLink: ["/home/payroll/management"],
          },
          {
            label: "Payroll Views",
            icon: "pi pi-fw pi-cog",
            routerLink: ["/home/payroll"],
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.Payroll_PayrollViews
            ),
            items: [
              {
                label: "Payroll Vs Contract",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Payroll_PayrollVsContract
                ),
                routerLink: ["/home/payroll/payroll-vs-contract"],
              },
              {
                label: "P.A.Y.E By State",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Payroll_PayeByState
                ),
                routerLink: ["/home/payroll/paye-state-report"],
              },
              {
                label: "Qty Paid Vs Qty Billed",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Payroll_QtyPaidVsQtyBilled
                ),
                routerLink: ["/home/payroll/paid-vs-qty"],
              },
            ],
          },
          {
            label: "Payroll Approval Setup",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_ApprovalSetup
            ),
            routerLink: ["/home/payroll/approval-setup"],
          },
          {
            label: "Batch Payroll Approval",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_BatchApproval
            ),
            routerLink: ["/home/payroll/approval"],
          },
          {
            label: "Single Payroll Approval",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_SingleApproval
            ),
            routerLink: ["/home/payroll/approval2"],
          },
          {
            label: "Special Allowance Req",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_SpecialAllowanceRequest
            ),
            routerLink: ["/home/payroll/special-request"],
          },
          {
            label: "Special Deduction Req",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_SpecialDeductionRequest
            ),
            routerLink: ["/home/payroll/special-request2"],
          },
          {
            label: "Special Request Approval",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Payroll_SpecialRequestApproval
            ),
            routerLink: ["/home/payroll/special-request-approval"],
          },
        ],
      },
      {
        label: "Organization",
        icon: "pi pi-fw pi-cog",
        visible: this.SystemPermission.HasPermissionGroup(
          PermissionGroupEnum.Organization
        ),
        items: [
          {
            label: "Organogram",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Organization_Organogram
            ),
            routerLink: ["/home/organization/organogram"],
          },
        ],
      },
      {
        label: "Convergence",
        icon: "pi pi-fw pi-cog",
        visible:
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.Convergence_Incident
          ) ||
          this.SystemPermission.HasPermissionGroup(
            PermissionGroupEnum.Convergence_Device
          ),
        items: [
          {
            label: "Incident Management",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.Convergence_Incident
            ),
            items: [
              {
                label: "Setup",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_Setup
                ),
                routerLink: ["/home/convergence/incident-management/setup"],
              },
              {
                label: "Setting",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_Setting
                ),
                routerLink: ["/home/convergence/incident-management/setting"],
              },
              {
                label: "Quick Capture",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_CaptureMobile
                ),
                routerLink: [
                  "/home/convergence/incident-management/mobile-capture",
                ],
              },
              {
                label: "Capture Incidence",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_CaptureIncidence
                ),
                routerLink: [
                  "/home/convergence/incident-management/capture-incidence",
                ],
              },
              {
                label: "Review Incidence",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_ReviewIncidence
                ),
                routerLink: [
                  "/home/convergence/incident-management/review-incident",
                ],
              },
              {
                label: "Approve Incidence",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_ApproveIncidence
                ),
                routerLink: ["/home/convergence/incident-management/approval"],
              },
              {
                label: "Notification Template",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_NotificationTemplate
                ),
                routerLink: [
                  "/home/convergence/incident-management/notification-template",
                ],
              },
              {
                label: "Notification Policy",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_NotificationPolicy
                ),
                routerLink: [
                  "/home/convergence/incident-management/notification-policy",
                ],
              },
              {
                label: "Cient Notification",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_ClientNotification
                ),
                routerLink: [
                  "/home/convergence/incident-management/client-notification",
                ],
              },
              // {
              //   label: "Cient-State Mapping ",
              //   icon: "pi pi-fw pi-id-card",
              //   // visible: this.SystemPermission.HasPermission(
              //   //   PermissionEnum.Convergence_Incident_ClientMappingByState
              //   // ),
              //   routerLink: [
              //     "/home/convergence/incident-management/client-state-mapping",
              //   ],
              // },
              {
                label: "Dashboard",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_Dashboard
                ),
                routerLink: ["/home/convergence/incident-management/dashboard"],
              },
              {
                label: "Report",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Incident_Report
                ),
                routerLink: ["/home/convergence/incident-management/report"],
              },
            ],
          },
          {
            label: "Device Management",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.Convergence_Device
            ),
            items: [
              {
                label: "Setup",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Device_Setup
                ),
                routerLink: ["/home/convergence/device-management/setup"],
              },
              {
                label: "Device Registration",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Device_DeviceRegistration
                ),
                routerLink: [
                  "/home/convergence/device-management/connectivity",
                ],
              },
              {
                label: "Map Client Device",
                icon: "pi pi-fw pi-id-card",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Convergence_Device_MapClientDevice
                ),
                routerLink: [
                  "/home/convergence/device-management/registration",
                ],
              },
              {
                label: "Dashboard",
                icon: "pi pi-fw pi-id-card",
                visible:
                  this.SystemPermission.HasPermission(
                    PermissionEnum.Convergence_Device_DashboardClientDistribution
                  ) ||
                  this.SystemPermission.HasPermission(
                    PermissionEnum.Convergence_Device_DashboardControlView
                  ),
                items: [
                  {
                    label: "Client Distribution",
                    icon: "pi pi-fw pi-id-card",
                    visible: this.SystemPermission.HasPermission(
                      PermissionEnum.Convergence_Device_DashboardClientDistribution
                    ),
                    routerLink: [
                      "/home/convergence/device-management/dashboard",
                    ],
                  },
                  {
                    label: "Control View",
                    icon: "pi pi-fw pi-id-card",
                    visible: this.SystemPermission.HasPermission(
                      PermissionEnum.Convergence_Device_DashboardControlView
                    ),
                    routerLink: [],
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        label: "Platform Benefits Management",
        icon: "pi pi-fw pi-cog",
        visible: this.SystemPermission.HasPermissionGroup(
          PermissionGroupEnum.PlatformBenefit
        ),
        items: [
          {
            label: "Create Platform",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.PlatformBenefit_CreatePlatform
            ),
            routerLink: [
              "/home/event-management/platform-benefit/create-platform",
            ],
          },
          {
            label: "Create Benefit",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.PlatformBenefit_CreateBenefit
            ),
            routerLink: [
              "/home/event-management/platform-benefit/create-benefit",
            ],
          },
          {
            label: "Map Platform Service",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.PlatformBenefit_MapPlatformService
            ),
            routerLink: [
              "/home/event-management/platform-benefit/map-platform-service",
            ],
          },
          {
            label: "Map Service Benefits",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.PlatformBenefit_MapServiceBenefit
            ),
            routerLink: [
              "/home/event-management/platform-benefit/map-service-benefit",
            ],
          },
          {
            label: "Special Request Workbench",
            icon: "pi pi-fw pi-id-card",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.PlatformBenefit_SpecialRequestWorkbench
            ),
            routerLink: [
              "/home/event-management/platform-benefit/special-request-workbench",
            ],
          },
        ],
      },
      {
        label: "Budget Management",
        icon: "pi pi-briefcase",
        routerLink: ["/home"],
        visible: this.SystemPermission.HasPermissionGroup(
          PermissionGroupEnum.Budget
        ),
        items: [
          {
            label: "Create/View Season",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Budget_SeasonSettings
            ),
            routerLink: ["/home/budget-season"],
          },

          {
            label: "Budget Cycle Module",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Budget_CycleSettings
            ),
            routerLink: ["/home/budget-cycle"],
          },

          {
            label: "Budget Distribution Module",
            visible: this.SystemPermission.HasPermission(
              PermissionEnum.Budget_Distributions
            ),
            routerLink: ["/home/budget-distributions"],
          },

          {
            label: "Budget Reviewer",
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.Budget_Reviewal
            ),
            routerLink: ["/home"],
            items: [
              {
                label: "Budget Reviewer Setup",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Budget_Reviewer_Setup
                ),
                routerLink: ["/home/budget-reviewer-setup"],
              },
              {
                label: "Budget Reviewer Page",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Budget_Reviewer_Page
                ),
                routerLink: ["/home/budget-reviewer-page"],
              },
            ],
          },
          {
            label: "Budget Approval",
            visible: this.SystemPermission.HasPermissionGroup(
              PermissionGroupEnum.Budget_Approval
            ),
            routerLink: ["/home"],
            items: [
              {
                label: "Budget Approval Setup",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Budget_Approval_Setup
                ),
                routerLink: ["/home/budget-approval-setup"],
              },
              {
                label: "Budget Approval Page",
                visible: this.SystemPermission.HasPermission(
                  PermissionEnum.Budget_Approval_Page
                ),
                routerLink: ["/home/budget-approval-page"],
              },
            ],
          },
        ],
      },
    ];
  }

  private connectionError() {
    this.messageService.add({
      severity: "error",
      summary: "Failed",
      detail: "Connection Error, Please try again",
    });
  }
}
