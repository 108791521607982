import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MessageService, ConfirmationService } from "primeng/api";
import { BreadcrumbService } from "src/app/breadcrumb.service";
import {
  AttachClientToOfficeVM,
  Branch,
  CustomerDivision,
  Office,
  User,
} from "src/app/interfaces/home";
import { BranchService } from "src/app/services/branch.service";
import { CustomerDivisionService } from "src/app/services/customer-division.service";
import { UserService } from "src/app/services/user.service";

@Component({
  selector: "app-attach-client-branch-office",
  templateUrl: "./attach-client-branch-office.component.html",
  styleUrls: ["./attach-client-branch-office.component.scss"],
})
export class AttachClientBranchOfficeComponent implements OnInit {
  attachmentForm: FormGroup;
  fetching: boolean;
  allBranches: Branch[] = [];
  selectedBranch: Branch;
  allOffices: Office[] = [];
  selectedOffice: Office;
  selectedBranchMembers: any[] = [];
  selectedMember: User;
  fetchingOfficeClients: boolean;
  officeClients: CustomerDivision[] = [];
  selectedOfficeClients: CustomerDivision[] = [];
  fetchingUnAssignedClients: boolean;
  unAssignedClients: CustomerDivision[] = [];
  selectedClient: CustomerDivision;
  unAssignedClientCols: any;

  constructor(
    private customerDivisionService: CustomerDivisionService,
    private userService: UserService,
    private branchService: BranchService,
    public formBuilder: FormBuilder,
    public messageService: MessageService,
    public confirmationService: ConfirmationService,
    private breadcrumbService: BreadcrumbService
  ) {
    this.attachmentForm = formBuilder.group({
      Branch: [""],
      Office: [""],
    });
  }

  ngOnInit(): void {
    this.breadcrumbService.setItems([
      { label: "Branch & Office", routerLink: ["home/account/branch-office"] },
      {
        label: "Attach Client",
        routerLink: ["home/account/attach-client-branch-office"],
      },
    ]);

    this.FetchAllBranches();
    this.FetchAllUnassignedClients();
  }

  async FetchAllBranches() {
    this.fetching = true;
    this.branchService.allBranch().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetching = false;
          return;
        }
        this.allBranches = data.responseData;
        this.fetching = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all branches at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetching = false;
      }
    );
  }

  async FetchAllUnassignedClients() {
    this.fetchingUnAssignedClients = true;
    this.customerDivisionService.GetClientNotAttachedToOffice().subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          this.fetchingUnAssignedClients = false;
          return;
        }
        this.unAssignedClients = data.responseData;
        this.fetchingUnAssignedClients = false;
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to fetch all unassigned at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
        this.fetchingUnAssignedClients = false;
      }
    );
  }

  LoadSelectedBranchOffices() {
    if (this.selectedBranch) {
      this.allOffices = this.selectedBranch.offices;
    } else {
      this.allOffices = [];
      this.selectedOffice = null;
    }
  }

  async LoadSelectedOfficeClients() {
    this.LoadSelectedOfficeMembers();

    this.officeClients = [];
    if (this.selectedOffice) {
      this.customerDivisionService
        .FindAllClientsInAnOffice(this.selectedOffice.id)
        .subscribe(
          async (data) => {
            if (data.responseCode != "00") {
              this.messageService.add({
                severity: "error",
                summary: data.responseMsg,
              });
              return;
            }

            this.officeClients = data.responseData;
          },
          (error) => {
            console.log("Error: " + JSON.stringify(error));
            this.messageService.add({
              severity: "error",
              summary: "Notice",
              detail:
                "Unable to fetch all office clients at the moment.. Reason: [" +
                (error ? error.error.message : "request failed - permission") +
                "]",
            });
          }
        );
    }
  }

  async LoadSelectedOfficeMembers() {
    this.selectedBranchMembers = [];
    if (this.selectedOffice) {
      this.userService.FindAllUsersInAnOffice(this.selectedOffice.id).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          this.selectedBranchMembers = data.responseData;
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to fetch all office members at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
    }
  }

  SelectMember(member: User) {
    this.selectedMember = member;
  }

  DetachFromOffice(client: CustomerDivision) {
    this.messageService.add({
      severity: "info",
      summary: "Notice",
      detail: "Detaching client from Office...",
    });

    this.customerDivisionService.DetachClientFromOffice(client.id).subscribe(
      async (data) => {
        if (data.responseCode != "00") {
          this.messageService.add({
            severity: "error",
            summary: data.responseMsg,
          });
          return;
        }

        this.messageService.add({
          severity: "success",
          summary: "Completed",
          detail: "Client Detached Successfully!",
        });
        this.unAssignedClients.push(client);
        const index = this.officeClients.indexOf(client);
        if (index > -1) {
          this.officeClients.splice(index, 1);
        }
      },
      (error) => {
        console.log("Error: " + JSON.stringify(error));
        this.messageService.add({
          severity: "error",
          summary: "Notice",
          detail:
            "Unable to detach client at the moment.. Reason: [" +
            (error ? error.error.message : "request failed - permission") +
            "]",
        });
      }
    );
  }

  AssignClientToOffice(client: CustomerDivision) {
    if (this.selectedOffice) {
      this.messageService.add({
        severity: "info",
        summary: "Notice",
        detail: "Attaching client to Office...",
      });

      const postData: AttachClientToOfficeVM = {
        branchId: this.selectedOffice.branchId,
        officeId: this.selectedOffice.id,
        customerDivisionId: client.id,
      };

      this.customerDivisionService.AttachClientToOffice(postData).subscribe(
        async (data) => {
          if (data.responseCode != "00") {
            this.messageService.add({
              severity: "error",
              summary: data.responseMsg,
            });
            return;
          }

          this.messageService.add({
            severity: "success",
            summary: "Completed",
            detail: "Client Attached Successfully!",
          });
          this.officeClients.push(client);
          const index = this.unAssignedClients.indexOf(client);
          if (index > -1) {
            this.unAssignedClients.splice(index, 1);
          }
        },
        (error) => {
          console.log("Error: " + JSON.stringify(error));
          this.messageService.add({
            severity: "error",
            summary: "Notice",
            detail:
              "Unable to attach client at the moment.. Reason: [" +
              (error ? error.error.message : "request failed - permission") +
              "]",
          });
        }
      );
    }
  }
}
